export default {
    title: "Чат",
    back: "На початок",
    content: {
        start: {
            type: 'message',
            title: 'Чому журналістам співпрацювати зі Збройними Силами України?',
            content: `Робота медіа в зоні ведення бойових дій є небезпечною, тому заради безпеки журналістів і військових на неї накладається ряд обмежень.<br/>
                       На цьому сайті можна отримати інформацію про акредитацію, ознайомитися з правилами роботи медіа у прифронтових районах та порядком взаємодії зі Збройними Силами України.`,
            trigger: 'options-1',
        },
        'options-1': {
            type: 'options',
            title: 'Виберіть тему',
            options: [
                { value: 'accreditation', label: 'Акредитація ЗСУ', trigger: 'accreditation' },
                { value: 'frontline-work', label: 'Робота поблизу фронту', trigger: 'frontline-work' },
                { value: 'contacts', label: 'Контакти для журналістів', trigger: 'contacts' },
                // submit-complaint
                { value: 'submit-complaint', label: 'Подати скаргу', trigger: 'submit-complaint' },
            ],
        },
        "accreditation": {
            type: 'message',
            title: 'Акредитація ЗСУ',
            trigger: 'options-accreditation',
            content: `<p>Акредитація Збройних Сил України дає працівникам медіа право виконувати редакційні завдання у районах ведення бойових дій та на військових об’єктах в тилу. Наявність акредитації підтверджується персоналізованим документом встановленого зразка.</p>`
        },
        //options-accreditation
        "options-accreditation": {
            type: 'options',
            title: 'Виберіть тему',
            options: [
                { value: 'rules', label: 'Правила', trigger: 'rules' },
                { value: 'documents-list', label: 'Необхідні документи', trigger: 'documents-list' },
                { value: 'submit-documents', label: 'Подати документи', trigger: 'submit-documents' },
                { value: 'check-status', label: 'Перевірити статус запиту', trigger: 'check-status' },
                { value: 'problem', label: 'Є проблема', trigger: 'problem' },
                { value: 'back', label: 'Повернутися', trigger: 'options-1' },
            ],
        },
        //rules
        "rules": {
            type: 'message',
            title: 'Правила акредитації ЗСУ',
            trigger: 'rules-options-1',
            content: `- Запит на акредитацію може подати представник медіа та блогер, зареєстрований в порядку, передбаченому Законом України «Про медіа».<br/> 
                      – Подана інформація перевіряється державними органами України.За результатами видається прескарта або повідомляється про відмову.<br/>
                      – Перевірка триває 10-15 днів, але за потреби може бути продовжена.<br/>
                      – Акредитація може бути припинена: за передання прескарти іншій особі, відмову дотримуватися або порушення <a href="/rules/" target="_new">Правил</a> роботи в зоні бойових дій.`
        },
        //rules-options-1
        "rules-options-1": {
            type: 'options',
            title: 'Виберіть тему',
            options: [
                { value: 'documents-list', label: 'Список необхідних документів', trigger: 'documents-list' },
                { value: 'submit-documents', label: 'Подати документи', trigger: 'submit-documents' },
                { value: 'back', label: 'Повернутися', trigger: 'options-accreditation' },
            ],
        },
        //documents-list
        "documents-list": {
            type: 'message',
            title: 'Список документів для отримання акредитації ЗСУ',
            trigger: 'documents-list-options-1',
            content: `<ul>
            <li>• заповнена <a href="https://www.mil.gov.ua/content/zmi/acreditation_2024.docx" target="_blank" >форма</a>;</li>
            <li>• скан ID-картки або двох перших сторінок паспорта;</li>
            <li>• скан посвідчення представника медіа (<sup>*</sup>не потрібний для фіксерів, <sup>**</sup>для зареєстрованих блогерів – скан підтверджуючого документа Нацради з питань телебачення і радіомовлення);</li>     
            <li>• скан листа-клопотання від керівника медіа;</li>
            <li>• скан фото високої роздільної здатності.</li>
            </ul>`
        },
        "documents-list-options-1": {
            type: 'options',
            title: 'Виберіть тему',
            options: [
                { value: 'submit-documents', label: 'Подати документи', trigger: 'submit-documents' },
                { value: 'back', label: 'Повернутися', trigger: 'options-accreditation' },
            ],
        },
        // "submit-documents": {
        //     type: 'message',
        //     title: 'Подати документи на отримання акредитації ЗСУ',
        //     trigger: 'submit-documents-options-1',
        //     content: `<form className="border border-white p-4 rounded-lg">
        //     <input type="file" name="documents" multiple />
        //     <button type="submit" className="flex bg-[#f29104] items-center justify-center rounded px-2 py-1 mt-2 bg-opacity-100 text-white hover:bg-opacity-70">Подати</button>
        //   </form>`
        // },
        "submit-documents-options-1": {
            type: 'options',
            title: 'Виберіть тему',
            options: [
                { value: 'frontline-work', label: 'Робота поблизу фронту', trigger: 'frontline-work' },
                { value: 'contacts', label: 'Контакти для журналістів', trigger: 'contacts' },
                { value: 'back', label: 'Повернутися', trigger: 'options-accreditation' },
            ],
        },
        "check-status": {
            type: 'message',
            title: 'Перевірити статус заявки',
            trigger: 'check-status-options-1',
            content: `<p>Зверніться на <a href="mailto:newsmodu@gmail.com">newsmodu@gmail.com</a> або за телефоном <a href="tel:+380894201864">+380-89-420-18-64</a>.</p>`
        },
        "check-status-options-1": {
            type: 'options',
            title: 'Виберіть тему',
            options: [
                { value: 'frontline-work', label: 'Робота поблизу фронту', trigger: 'frontline-work' },
                { value: 'contacts', label: 'Контакти для журналістів', trigger: 'contacts' },
                { value: 'back', label: 'Повернутися', trigger: 'options-accreditation' },
            ],
        },
        "problem": {
            type: 'message',
            title: 'Маєте проблему з акредитацією ЗСУ?',
            trigger: 'problem-options-1',
            content: `<p>
            Переглянути <a href="/rules" target="_new">правила</a><br/><br/><a href="/faq" target="_new">Поширені запитання</a>
            </p>`
        },
        "problem-options-1": {
            type: 'options',
            title: 'Виберіть тему',
            options: [
                { value: 'check-status', label: 'Немає відповіді на подану заявку', trigger: 'check-status' },
                { value: 'refusal-reason', label: 'Отримали відмову в акредитації', trigger: 'refusal-reason' },
                { value: 'lost-press-card', label: 'Загублено прескарту', trigger: 'lost-press-card' },
                { value: 'back', label: 'Повернутися', trigger: 'options-accreditation' },
            ],
        },
        "refusal-reason": {
            type: 'message',
            title: 'Якщо відмовили в акредитації ',
            trigger: 'options-refusal-reason',
            content: `<p>Про відмову в акредитації ЗСУ має бути повідомлено разом з обґрунтуванням такого рішення. Якщо вам не повідомили про причину відмови, зверніться на <a href="mailto:newsmodu@gmail.com">newsmodu@gmail.com</a> або за телефоном <a href="tel:+380894201864">+380-89-420-18-64</a>.Щоб оскаржити відмову, надішліть офіційний лист від керівника (редактора) медіа на <a href="mailto:newsmodu@gmail.com">newsmodu@gmail.com</a>.</p>`
        },
        "options-refusal-reason": {
            type: 'options',
            title: 'Виберіть тему',
            options: [
                { value: 'back', label: 'Повернутися', trigger: 'options-accreditation' },
            ],
        },
        "lost-press-card": {
            type: 'message',
            title: 'Якщо загубили прескарту',
            trigger: 'options-lost-press-card',
            content: `<p>Негайно зверніться за телефоном <a href="tel:+380894201864">+380-89-420-18-64</a> або напишіть на електронну адресу <a href="mailto:newsmodu@gmail.com">newsmodu@gmail.com</a>, щоб отримати інструкції щодо подальших дій.</p>`
        },
        "options-lost-press-card": {
            type: 'options',
            title: 'Виберіть тему',
            options: [
                { value: 'back', label: 'Повернутися', trigger: 'options-accreditation' }, // замініть 'previous-step-id' на ID попереднього кроку
            ],
        },
        //frontline-work
        "frontline-work": {
            type: 'message',
            title: 'Робота в зоні бойових дій.',
            content: ``,
            trigger: 'frontline-work-options',
        },
        "frontline-work-options": {
            type: 'options',
            title: 'Виберіть тему',
            options: [
                { value: 'have-accreditation', label: 'Маю акредитацію ЗСУ', trigger: 'have-accreditation' },
                { value: 'no-accreditation', label: 'Акредитації ЗСУ не маю', trigger: 'no-accreditation' },
                { value: 'obligations', label: 'Обов’язки представників медіа', trigger: 'obligations' },
                { value: 'questions-about-work', label: 'Є питання щодо роботи медіа в зоні бойових дій?', trigger: 'questions-about-work' },
                { value: 'back', label: 'Повернутися', trigger: 'options-1' }
            ],
        },
        "have-accreditation": {
            type: 'message',
            title: 'Маю акредитацію ЗСУ',
            content: `<p>ЗСУ треба переконатися, що оперативна обстановка в зоні бойових дій дозволяє медіа працювати в наміченому вами районі. Для цього вам необхідно отримати погодження органу зв’язків з громадськістю відповідного оперативно-стратегічного угрупування військ.</p>`,
            trigger: 'where-to-work'
        },
        "where-to-work": {
            type: 'message',
            title: 'Де ви плануєте працювати?',
            content: '',
            trigger: 'region-selection'
        },
        "region-selection": {
            type: 'options',
            title: 'Виберіть область',
            options: [
                {
                    value: 'donetsk', label: 'Донецька область (→ ОСУВ «Хортиця»)', trigger: 'region-info', metadata: {
                        region: 'Донецька область',
                        osuv: 'ОСУВ «Хортиця»',
                        emails: ['presscentrjfo@post.mil.gov.ua']
                    }
                },
                {
                    value: 'zaporizhzhia', label: 'Запорізька область (→ ОСУВ «Таврія»)', trigger: 'region-info',
                    metadata: {
                        region: 'Запорізька область',
                        osuv: 'ОСУВ «Таврія»',
                        emails: ['tavriyapress@post.mil.gov.ua']
                    }
                },
                {
                    value: 'luhansk', label: 'Луганська область (→ ОСУВ «Хортиця»)', trigger: 'region-info',
                    metadata: {
                        region: 'Луганська область',
                        osuv: 'ОСУВ «Хортиця»',
                        emails: ['presscentrjfo@post.mil.gov.ua']
                    }
                },
               
                {
                    value: 'sumy', label: 'Сумська область (→ ОСУВ «Північ»)', trigger: 'region-info',
                    metadata: {
                        region: 'Сумська область',
                        osuv: 'ОСУВ «Північ»',
                        emails: ['pressa.koszsu@post.mil.gov.ua']
                    }
                },
                {
                    value: 'kharkiv', label: 'Харківська область (→ ОСУВ «Хортиця»)', trigger: 'region-info',
                    metadata: {
                        region: 'Харківська область',
                        osuv: 'ОСУВ «Хортиця»',
                        emails: ['presscentrjfo@post.mil.gov.ua']
                    }
                },
                {
                    value: 'kherson', label: 'Херсонська область (→ ОСУВ «Таврія»)', trigger: 'region-info',
                    metadata: {
                        region: 'Херсонська область',
                        osuv: 'ОСУВ «Таврія»',
                        emails: ['tavriyapress@post.mil.gov.ua']
                    }
                },
                { value: 'back', label: 'Повернутися', trigger: 'frontline-work-options' },
            ],
        },
        "region-options": {
            type: 'options',
            title: 'Виберіть область',
            options: [
                { value: 'back', label: 'Повернутися', trigger: 'region-selection' },
            ]
        },
        "no-accreditation": {
            type: 'message',
            title: 'Вам необхідно отримати акредитацію ЗСУ',
            trigger: 'accreditation-options',
            content: `<p>Для роботи в зоні бойових дій працівник медіа має одержати акредитацію ЗСУ та погодження служби зв’язків з громадськістю ОСУВ. Це правило діє навіть в тому випадку, якщо військовослужбовці ЗСУ запросили вас до себе на позиції та гарантували безпеку.</p>`
        },
        "accreditation-options": {
            type: 'options',
            title: 'Виберіть тему',
            options: [
                { value: 'zsu-accreditation', label: 'Акредитація ЗСУ', trigger: 'accreditation' },
                { value: 'back', label: 'Повернутися', trigger: 'frontline-work' }, // Замените 'previous-step-id' на ID предыдущего шага
            ],
        },
        // obligations
        "obligations": {
            type: 'message',
            title: 'Обов’язки працівників медіа в зоні бойових дій',
            trigger: 'obligations-options',
            content: `<ul>
            <li>• пред’являти особисті документи, акредитацію та погодження відповідного ОСУВ;</li>
            <li>• пересуватися за погодженим планом;</li>
            <li>• носити бронежилет, шолом, аптечку;</li>     
            <li>• розміщувати на транспорті знаки розпізнавання представників медіа;</li>
            <li>• передавати носії даних для перевірки на наявність інформації, що не підлягає розголошенню, і видаляти таку інформацію;.</li>
            <li>• розголошувати <a href="/rules/" target="_new">таємну та чутливу інформацію;</a></li>
            <li>• не вдягати формений одяг та знаки розрізнення ЗСУ, не брати до рук зброю та боєприпаси;</li>
            </ul><br/> <a href="/rules/" target="_new">Докладно про обовʼязки медіа</a>.`
        },
        "obligations-options": {
            type: 'options',
            title: 'Виберіть тему',
            options: [
                { value: 'obligatations-accreditations', label: 'Отримати акредитацію', trigger: 'accreditation' },
                { value: 'obligatations-region-selection', label: 'Отримати погодження ОСУВ', trigger: 'region-selection' }
            ],
        },

        // Є питання щодо роботи медіа в зоні бойових дій?
        "questions-about-work": {
            type: 'options',
            title: 'Є питання щодо роботи медіа в зоні бойових дій?',
            options: [
                { value: 'working-zone', label: 'Зони роботи', trigger: 'working-zone' },
                { value: 'responsibility-zsu', label: 'Відповідальність ЗСУ', trigger: 'responsibility-zsu' },
                { value: 'media-restrictions', label: 'Обмеження в роботі медіа', trigger: 'media-restrictions' },
                { value: 'confidential-information', label: 'Відповідальність за розголошення таємної інформації', trigger: 'confidential-information' },
                // back to previous step
                { value: 'back', label: 'Повернутися', trigger: 'frontline-work' },
            ],
        },
        'working-zone': {
            type: 'message',
            title: 'Зони роботи',
            content: `<p>
            Оперативно-стратегічні угрупування військ ЗСУ поділяють райони своєї відповідальності на зони:<br/>
                <ul>
                    <li>• У зеленій зоні медіа можуть працювати без супроводу уповноваженої особи ЗСУ.
                    <li>• У жовтій медіа можуть працювати без супроводу в громадських місцях, відкритих для цивільного населення
                    <li>• У червоній зоні робота медіа дозволяється у виняткових випадках і лише у супроводі уповноваженої особи ЗСУ
                </ul>
            </p>`,
            trigger: 'questions-about-work'
        },
        'responsibility-zsu': {
            type: 'message',
            title: 'Відповідальність ЗСУ',
            content: `<p>
            ЗСУ докладають максимум зусиль для збереження життя і здоров’я представників медіа, але не несуть за це безпосередньої відповідальності. Тому журналісти зобов’язані використовувати засоби індивідуального бронезахисту та мати при собі аптечку.
            </p>`,
            trigger: 'questions-about-work'
        },
        'media-restrictions': {
            type: 'message',
            title: 'Обмеження в роботі медіа',
            content: `<p>
            Для підтримання операційної безпеки посадові особи ЗСУ в окремих випадках можуть обмежувати фото- і відеозйомку. Підрозділи безпеки операцій також мають право попередньо переглядати відзняті фото- та відеоматеріали і вимагати видалити їх з носіїв даних.
            </p>`,
            trigger: 'questions-about-work'
        },
        'confidential-information': {
            type: 'message',
            title: 'Відповідальність за розголошення таємної інформації',
            content: `<p>
            Статтею 114-2 Кримінального кодексу України передбачене позбавлення волі на строк від 3 до 5 років за несанкціоноване поширення інформації про направлення, переміщення зброї, озброєння та бойових припасів в Україну, рух, переміщення або розміщення ЗСУ чи інших утворених відповідно до законів України військових формувань, вчинене в умовах воєнного або надзвичайного стану.
            </p>`,
            trigger: 'questions-about-work'
        },
        "contacts": {
            type: 'message',
            title: 'Контакти',
            trigger: 'contacts-options',
            content: ``,
        },
        "contacts-options": {
            type: 'options',
            title: 'Виберіть тему',
            options: [
                { value: 'mod-contacts', label: 'Управління преси та інформації Міністерства оборони України', trigger: 'mod-contacts' },
                { value: 'general-staff-contacts', label: 'Головне управління комунікацій Збройних Сил України', trigger: 'general-staff-contacts' },
                { value: 'branches-contacts', label: 'Командування родів, видів військ і сил ЗСУ', trigger: 'branches-contacts' },
                { value: 'osuv-contacts', label: 'Оперативно-стратегічні угруповання військ ЗСУ', trigger: 'osuv-contacts' },
                { value: 'minister-interview-requests', label: 'інтервʼю з Міністром оборони України та його заступниками', trigger: 'minister-interview-requests' },
                { value: 'supremecommander-interview-requests', label: 'інтервʼю з Головнокомандучем ЗСУ і його заступниками, начальником Генштабу, його заступниками, командувачами ОСУВ та родів, видів військ і сил ЗСУ', trigger: 'supremecommander-interview-requests' },
                { value: 'filming-requests', label: 'Зйомка бойової техніки, що надійшла від іноземних партнерів', trigger: 'filming-requests' },
                { value: 'back', label: 'Повернутися', trigger: 'options-1' }
            ],
        },
        "mod-contacts": {
            type: 'message',
            title: 'Управління преси та інформації Міністерства оборони України',
            content: `<p><a href="mailto:psmodu@post.mil.gov.ua">psmodu@post.mil.gov.ua</a><br><a href="tel:+380963109450">+380-96-310-9450</a></p>`,
            trigger: 'contacts-options'
        },
        "general-staff-contacts": {
            type: 'message',
            title: 'Управління зв’язків з громадськістю Збройних Сил України (пресслужба Генштабу)',
            content: `<p><a href="mailto:press@post.mil.gov.ua">press@post.mil.gov.ua</a><br><a href="tel:+380444544255">+380-44-454-4255</a></p>`,
            trigger: 'contacts-options'
        },
        "branches-contacts": {
            type: 'message',
            title: 'Командування родів, видів військ і сил ЗСУ',
            content: `<p>Сухопутні війська<br>  
                          <a href="mailto:press.ksv@mil.gov.ua">press.ksv@mil.gov.ua</a>
                       </p>
                        <br>
                       <p>Повітряні Сили<br>  
                            <a href="mailto:uafpc@ps.mil.gov.ua">uafpc@ps.mil.gov.ua</a>
                       </p>
                        <br>
                       <p>Військово-Морські сили<br>  
                            <a href="mailto:admindep@post.mil.gov.ua">admindep@post.mil.gov.ua</a>
                       </p>
                        <br>
                        <p>Десантно-штурмові війська<br>  
                            <a href="mailto:pressa_dshv@post.mil.gov.ua">pressa_dshv@post.mil.gov.ua</a>
                       </p>
                        <br>
                        <p>Сили спеціальних операцій<br>  
                            <a href="mailto:press_softcom@post.mil.gov.ua">press_softcom@post.mil.gov.ua</a>
                       </p>
                        <br>
                        <p>Медичні сили<br>  
                            <a href="mailto:kms_zsu@post.mil.gov.ua">kms_zsu@post.mil.gov.ua</a>
                       </p>
                        <br>
                        <p>Сили підтримки<br>  
                            <a href="mailto:ksppress@post.mil.gov.ua">ksppress@post.mil.gov.ua</a>
                       </p>
                        <br>
                        <p>Сили логістики<br>  
                            <a href="mailto:prs-ksl@post.mil.gov.ua">prs-ksl@post.mil.gov.ua</a>
                       </p>
                        <br>
                        <p>Війська зв'язку та кібербезпеки:<br>  
                            <a href="mailto:ccs_command_afua@post.mil.gov.ua">ccs_command_afua@post.mil.gov.ua</a>
                       </p>
                       <br>
                        <p>Командування територіальної оборони Збройних Сил України:<br>  
                            <a href="mailto:tro.info@post.mil.gov.ua">tro.info@post.mil.gov.ua</a>
                            <a href="mailto:to_info@post.mil.gov.ua">to_info@post.mil.gov.ua</a>
                       </p>
                       <br>
                        <p>Головне управління Військової служби правопорядку Збройних Сил України:<br>  
                            <a href="mailto:och_vsp@post.mil.gov.ua">och_vsp@post.mil.gov.ua</a>
                            <a href="mailto:pressvsp@gmail.com">pressvsp@gmail.com</a>
                       </p>
                       <br>
                        <p>Командування об'єднаних сил Збройних Сил України:<br>  
                            <a href="mailto:pressa.koszsu@post.mil.gov.ua">pressa.koszsu@post.mil.gov.ua</a>
                       </p>`,
            trigger: 'contacts-options'
        },
        "osuv-contacts": {
            type: 'message',
            title: 'Оперативно-стратегічні угруповання військ ЗСУ',
            content: `<p><strong>ОТУ «СІВЕРСЬК»</strong><br>  
                          <a href="mailto:presspivnich@post.mil.gov.ua">presspivnich@post.mil.gov.ua</a>
                       </p>
                        <br>
                       <p><strong>ОСУВ «Таврія»</strong><br>  
                            <a href="mailto:tavriyapress@post.mil.gov.ua">tavriyapress@post.mil.gov.ua</a>
                       </p>
                        <br>
                       <p><strong>ОСУВ «Хортиця»</strong><br>  
                            <a href="mailto:presscentrjfo@post.mil.gov.ua">presscentrjfo@post.mil.gov.ua</a>
                       </p>`,
            trigger: 'contacts-options'
        },
        "filming-requests": {
            type: 'message',
            title: 'Зйомка бойової техніки, що надійшла від іноземних партнерів',
            content: `<p><a href="mailto:press@post.mil.gov.ua">press@post.mil.gov.ua</a><br><a href="tel:+380444544255">+380-44-454-4255</a></p>`,
            trigger: 'contacts-options'
        },
        "minister-interview-requests": {
            type: 'message',
            title: 'інтервʼю з Міністром оборони України та його заступниками',
            content: `<p><a href="mailto:psmodu@post.mil.gov.ua">psmodu@post.mil.gov.ua</a><br><a href="tel:+380963109450">+380-96-310-9450</a></p>`,
            trigger: 'contacts-options'
        },
        "supremecommander-interview-requests": {
            type: 'message',
            title: 'інтервʼю з Головнокомандучем ЗСУ і його заступниками, начальником Генштабу, його заступниками, командувачами ОСУВ та родів, видів військ і сил ЗСУ',
            content: `<p><a href="mailto:press@post.mil.gov.ua">press@post.mil.gov.ua</a><br><a href="tel:+380444544255">+380-44-454-4255</a></p>`,
            trigger: 'contacts-options'
        },
    }
}
