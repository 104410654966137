// Lang/ua/Contacts.js
export default {
    title: "Contacts of the communications units of the Armed Forces of Ukraine",
    contactInfo: [
        {
            name: "Main Communications Directorate of the Armed Forces of Ukraine",
            email: "press@post.mil.gov.ua",
            phone: "+380-44-454-4255",
            socialLinks: {
                facebook: "https://www.facebook.com/GeneralStaff.ua",
                telegram: "https://t.me/GeneralStaffZSU",
                instagram: "https://www.instagram.com/uaf_general_staff",
                youtube: "https://www.youtube.com/@GeneralStaffUA",
                twitter: "https://twitter.com/GeneralStaffUA"
            }
        },
        {
            name: "Khortytsia operational and strategic military group",
            email: "presscentrjfo@post.mil.gov.ua"
        },
        {
            name: "Tavriya operational military group",

            email: "tavriyapress@post.mil.gov.ua",
            socialLinks: {
                facebook: "https://www.facebook.com/people/Сили-оборони-Півдня-України/61558717479769/"
            }
        },

        {
            name: "Siversk operational and tactical group",
            email: "presspivnich@post.mil.gov.ua"
        },
        {
            name: "Requests for shooting military equipment received from foreign partners",
            email: "press@post.mil.gov.ua",
            phone: "+380-44-454-4255"
        },
        {
            name: "Requests for interviews with the Minister of Defense of Ukraine and his deputies",
            email: "psmodu@post.mil.gov.ua",
            phone: "+380-96-310-9450"
        },
        {
            name: "Requests for interviews with the Commander-in-Chief of the Armed Forces of Ukraine and his deputies, the Chief of the General Staff, his deputies, commanders of the operational and strategic military groups and other units of the Armed Forces of Ukraine",
            email: "press@post.mil.gov.ua",
            phone: "+380-44-454-4255"
        },
        {
            name: "Ground Forces Command",
            email: "press.ksv@mil.gov.ua",
            socialLinks: {
                facebook: "https://www.facebook.com/UALandForces",
                telegram: "https://t.me/landforcesofukraine",
                instagram: "https://www.instagram.com/ukrainianlandforces/",
                youtube: "https://www.youtube.com/channel/UCLvvixSoq5iJD4J30DD8F9g",
                tiktok: "https://www.tiktok.com/@landforcesofukraineoff"
            }
        },
        {
            name: "Air Force Command",
            email: "uafpc@ps.mil.gov.ua",
            socialLinks: {
                facebook: "https://www.facebook.com/kpszsu/",
                telegram: "https://t.me/kpszsu",
                instagram: "https://www.instagram.com/air.force.ua.official",
                youtube: "https://www.youtube.com/channel/UCfg-2zTad5ZVU1fQEtT6DSA",
                twitter: "https://twitter.com/KpsZSU",
                tiktok: "https://www.tiktok.com/@air.force.ua_official?fbclid=IwAR0A2vFZF1f36CLUlO_9u1qRTwNdrp5p8JtXE-_8jZlWjOEYfljRNup5J-E"
            }
        },
        {
            name: "Command of the Naval Forces",
            email: "admindep@post.mil.gov.ua",
            socialLinks: {
                facebook: "https://www.facebook.com/navy.mil.gov.ua/",
                telegram: "https://t.me/ukrainian_navy",
                instagram: "https://www.instagram.com/ua_navy/",
                youtube: "https://www.youtube.com/watch?v=NZewCissVvM",
                twitter: "https://twitter.com/UA_NAVY"
            }
        },
        {
            name: "Command of Airborne Assault Troops",
            email: "pressa_dshv@post.mil.gov.ua",
            socialLinks: {
                facebook: "https://www.facebook.com/www.dshv.mil.gov.ua",
                telegram: "https://t.me/s/ua_dshv",
                instagram: "https://www.instagram.com/air_assault_forces_of_ukraine/?hl=ru",
                youtube: "https://www.youtube.com/@-dshv_ua"
            }
        },
        {
            name: "Special Operations Forces Command",
            email: "press_softcom@post.mil.gov.ua",
            socialLinks: {
                facebook: "https://www.facebook.com/usofcom",
                telegram: "https://t.me/ukr_sof",
                instagram: "https://instagram.com/sof_ua?igshid=YmMyMTA2M2Y=",
                twitter: "https://twitter.com/sof_ukr?s=21&t=pp2XA0g73rnlOyCqna80aQ"
            }
        },
        {
            name: "Command of the Medical Forces",
            email: "kms_zsu@post.mil.gov.ua",
            socialLinks: {
                facebook: "https://www.facebook.com/Ukrmilitarymedic/"
            }
        },
        {
            name: "Support Forces Command",
            email: "ksppress@post.mil.gov.ua",
            socialLinks: {
                facebook: "https://www.facebook.com/KSP.ZSU/",
                instagram: "https://www.instagram.com/ksp_uaf/",
                tiktok: "https://www.tiktok.com/@ksp_uaf",
                twitter: "https://x.com/ksp_uaf",
                youtube: "https://www.youtube.com/@Supportforcesua",
                telegram: "https://t.me/ksp_zsu"
            }
        },
        {
            name: "Logistics Forces Command",
            email: "prs-ksl@post.mil.gov.ua",
            socialLinks: {
                facebook: "https://www.facebook.com/LogisticsZSU",
            }
        },
        {
            name: "Command of the Communications and Cyber Security Forces",
            email: "ccs_command_afua@post.mil.gov.ua",
            socialLinks: {
                facebook: "https://www.facebook.com/signalcybersecutirycommand"
            }
        },
        {
            name: "Territorial Defense Command of the of the Armed Forces of Ukraine",
            emails: ["tro.info@post.mil.gov.ua", "to_info@post.mil.gov.ua"],
            phone: "0-800-507-028",
            socialLinks: {
                website: "https://tro.mil.gov.ua/",
                facebook: [
                    "https://www.facebook.com/tro.media.news",
                    "https://www.facebook.com/TerritorialDefenseForces"
                ],
                instagram: [
                    "https://www.instagram.com/tro.media.news/",
                    "https://www.instagram.com/territorial_defense_forces/"
                ],
                twitter: "https://x.com/tdf_ua",
                youtube: "https://www.youtube.com/@terytorialna.oborona"
            }
        },
        {
            name: "The Main Directorate of the Military Police Service of the Armed Forces of Ukraine",
            emails: ["och_vsp@post.mil.gov.ua", "pressvsp@gmail.com"],
            socialLinks: {
                facebook: "https://www.facebook.com/UA.Military.Police/"
            }
        },
        {
            name: "The Joint Forces Command of the Armed Forces of Ukraine",
            emails: ["pressa.koszsu@post.mil.gov.ua"],
            socialLinks: {
                website: "https://kos.zsu.gov.ua/",
                facebook: "https://www.facebook.com/JointForcesCommandAFU",
                twitter: "https://twitter.com/combined2forces"
            }
        }
    ]
};
