export default {
    title: "Error",
    complaint: 'Текст скарги (обов.)',
    fullname: "Ваше імʼя (обов.)",
    // Заповніть всі обов\'язкові поля
    requiredFields: 'Заповніть всі обов\'язкові поля',
    // Документи успішно подано
    documentsSubmitted: 'Документи успішно подано',
    // error
    error: 'Помилка при поданні документів',
    remove: 'Видалити',
    download: 'Завантажити',
    emailPlaceholder: 'Адреса вашого E-Mail',
    addPlaceholder: 'Додайте файли (опц.)',
    docFile: '*Файл з заповненою формою (<a href="https://www.mil.gov.ua/content/zmi/Application_Form_2023.docx" target="_new">скачати</a> зразок)',
    idScan: 'Скан ID картки',
    passportScans: 'Скан паспорта',
    mediaID: '*Скан посвідчення представника медіа',
    letter: '*Скан листа-заяви від керівника медіа',
    photo: '*Скан фотографії паспортного формату',
    submit: 'Надіслати',
    fullNameRequired: 'Введіть ваше імʼя',
    phoneRequired: 'Введіть ваш телефон',
    complaintRequired: 'Введіть текст скарги',
    //Перетягніть файли сюди або натисніть тут, щоб вибрати
    dropFiles: 'Перетягніть файли сюди або натисніть тут, щоб вибрати',
    or: 'або',
    ur_phone: "Ваш телефон (опц.)",
    // Щоб подати заявку, підтвердіть свою пошту
    confirmEmail: 'Щоб подати скаргу, підтвердіть адресу своєї пошти',
    // Введіть код
    enterCode: 'Введіть код',
    //Отримати код
    getCode: 'Отримати код',
    //Код було відправлено на пошту
    codeSent: 'Введіть код який було надіслано на адресу',
    //Підтвердити
    confirm: 'Підтвердити',
    //Наступний крок
    next: 'Наступний крок',
    //Змінити пошту
    changeEmail: 'Змінити адресу E-Mail',
    //Step
    step: 'Крок',
    // із
    of: 'із',
    //Невірний код підтвердження 
    wrongCode: 'Невірний код підтвердження',
    // Назад
    back: 'Назад',
    recaptchaError: 'Перевірка reCAPTCHA не пройшла. Будь ласка, спробуйте ще раз.',
    // Надіслати
    send: 'Надіслати',
    complaintSubmitted: 'Дякуємо, вашу скаргу надіслано',
    // form in bot
    title: 'Подати документи на отримання акредитації ЗСУ',
    scanOrId: 'Будь ласка, завантажте або скан ID картки, або скани паспорта.',
}