//ua

export default {
    // Заповніть всі обов\'язкові поля
    requiredFields: 'Заповніть всі обов\'язкові поля',
    // Документи успішно подано
    documentsSubmitted: 'Документи успішно подано',
    // error
    error: 'Помилка при поданні документів',

    docFile: '*Файл з заповненою формою (<a href="https://www.mil.gov.ua/content/zmi/Application_Form_2023.docx" target="_new">скачати</a> зразок)',
    idScan: 'Скан ID картки',
    passportScans: 'Скан паспорта',
    mediaID: '*Скан посвідчення представника медіа',
    letter: '*Скан листа-заяви від керівника медіа',
    photo: '*Скан фотографії паспортного формату',
    submit: 'Надіслати',
    //Перетягніть файли сюди або натисніть тут, щоб вибрати
    dropFiles: 'Перетягніть файли сюди або натисніть тут, щоб вибрати',
    or: 'або',
    // Щоб подати заявку, підтвердіть свою пошту
    confirmEmail: 'Щоб подати заявку, підтвердіть свою пошту',
    // Введіть код
    enterCode: 'Введіть код',
    //Отримати код
    getCode: 'Отримати код',
    //Код було відправлено на пошту
    codeSent: 'Код було відправлено на пошту',
    //Підтвердити
    confirm: 'Підтвердити',
    //Наступний крок
    next: 'Наступний крок',
    //Змінити пошту
    changeEmail: 'Змінити пошту',
    //Step
    step: 'Крок',
    // із
    of: 'із',
    //Невірний код підтвердження 
    wrongCode: 'Невірний код підтвердження',
    // Назад
    back: 'Назад',
    recaptchaError: 'Перевірка reCAPTCHA не пройшла. Будь ласка, спробуйте ще раз.',
    // Надіслати
    send: 'Надіслати',
    emailPlaceholder: 'Адреса вашого E-Mail',
    codePlaceholder: 'Введіть код',
    // form in bot
    title: 'Подати документи на отримання акредитації ЗСУ',
    scanOrId: 'Будь ласка, завантажте або скан ID картки, або скани паспорта.',

    description: {
        title: 'Щоб отримати акредитацію, вам потрібно подати заявку, до якої додати наступні документи',
        list: [
            'Заповнена анкета <a href="https://www.mil.gov.ua/content/zmi/Application_Form_2023.docx" target="_new">(завантажити зразок)</a>;',
            'ID-картка або 1-3 сторінки паспорта;',
            'посвідчення представника медіа (не є обов’язковим для фіксерів / зареєстровані блогери подають копію підтверджувального документу, виданого Національною радою України з питань телебачення і радіомовлення);',
            'подання з клопотанням надати акредитацію (лист-заява від керівника медіа);',
            'фото особи високої роздільної здатності.'
        ],
        footer: 'Завантажте їх покроково у відповідні розділи заявки.'
    },

    scanOrId: 'Будь ласка, завантажте або скан ID картки, або скани паспорта.',
    emailRequired: 'Введіть вашу пошту',
    codeRequired: 'Введіть код',
    docFileRequired: 'Завантажте файл з заповненою формою',
    scanOrIdRequired: 'Завантажте скан ID картки або паспорта',
    letterRequired: 'Завантажте скан листа-заяви',
    photoRequired: 'Завантажте скан фотографії',
};