import { ReactComponent as DesctopSchema } from "../../pages/svg-schema/Scheme_Mediarelations_Desktop_9.svg"
import { ReactComponent as MobileSchema } from "../../pages/svg-schema/Scheme_Mediarelations_Mobile_9.svg"


export default {
    "title": "Алгоритм взаємодії",
    "description": "Міністерство оборони України вітає прагнення журналістів доносити правду про війну. Водночас робота медіа в зоні ведення бойових дій є небезпечною, тому має ряд правил і обмежень задля безпеки – як представників медіа, так і військових.<br/>Цей ресурс створено, щоб допомогти медійникам налагодити взаємодію зі Збройними Силами України, отримати акредитацію, ознайомитися із правилами роботи в районах бойових дій тощо.",
    "poster": 'Взаємодія з\nмедіа',
    DesctopSchema: DesctopSchema,
    MobileSchema: MobileSchema
}