import './App.css';
import React, { useEffect, useRef, useState } from 'react';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Navigation from './layout/Navigation';
import { BrowserRouter as Router, Routes, Route, Navigate, Redirect, Link } from 'react-router-dom';
import { tabs, tabs2 } from './layout/pages';
import Poster from './components/Poster';
import { useLanguage } from './contexts/LanguageContext';
//bot
// import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';

// bot 2
import CustomChatbot from './CustomChatbot';

function NoMatch() {
  const { language, getTranslation } = useLanguage();
  const t = getTranslation('404');
  return (
    <section className="flex items-center h-full p-16">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-9xl ">
            <span className="sr-only">{t.title}</span>404
          </h2>
          <div dangerouslySetInnerHTML={
            {
              __html: t.text
            }
          } />
          <Link rel="noopener noreferrer" to={
            language === 'UA' ? '/home' : '/en/home'
          } className="px-8 py-3 font-semibold rounded ">{t.linkText}</Link>
        </div>
      </div>
    </section>
  );
}


function App() {
  const { language, getTranslation } = useLanguage();
  const t = getTranslation('Main');
  const [isChatOpen, setChatOpen] = useState(false);
  const refBot = useRef(null);
  const footerRef = useRef(null);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const toggleChat = () => {
    setChatOpen(!isChatOpen);
  };

  const checkFooterPosition = () => {
    if (footerRef.current) {
      const footerPosition = footerRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsButtonVisible(windowHeight - footerPosition < 15);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkFooterPosition);
    return () => {
      window.removeEventListener('scroll', checkFooterPosition);
    };
  }, [footerRef]);



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (refBot.current && !refBot.current.contains(event.target)) {
        setChatOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refBot, language]);


  useEffect(() => {
    if (isChatOpen && refBot.current) {
      const content = refBot.current.querySelector('.rsc-content')
      if (content) {
        content.addEventListener('DOMNodeInserted', () => {
          const lastMessageBlock = [...content.querySelectorAll('.rsc-ts-user')].at(-1)
          // just scroll to the last message withouth any animation
          if (!lastMessageBlock) return;

          lastMessageBlock.scrollIntoView({ behavior: 'auto' })

        });

      }
    }
  }, [isChatOpen, refBot, language]);

  return (
    <div className="overflow-y-hidden min-h-screen flex flex-col">
      <Header />

      <Poster title={t.poster} />

      <Navigation />

      <Routes>
        <Route exact path="/" element={<Navigate to="/home" replace />} />
        <Route exact path="/en" element={<Navigate to="/en/home" replace />} />

        {tabs2.map((tab, tabIndex) => (
          <React.Fragment key={tabIndex}>
            {Object.entries(tab.links).map(([lang, links]) => (
              links.map((link, linkIndex) => (
                <Route
                  key={linkIndex}
                  path={link.path}
                  element={link.Component}
                />
              ))
            ))}
          </React.Fragment>
        ))}

        <Route
          path="*"
          element={<NoMatch />}
        />
      </Routes>

      <Footer footerRef={footerRef} />
      <div ref={refBot}>
        {isButtonVisible ? (
          <button
            onClick={toggleChat}
            className='fixed flex items-center justify-center z-50 bg-[#4D4634] hover:bg-opacity-70 text-white rounded-full shadow-lg w-14 h-14'
            style={{ position: 'fixed', bottom: '20px', right: '20px', borderRadius: '50%' }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="px-2 py-2 w-12 h-12"
            >
              <path
                fillRule="evenodd"
                d="M4.804 21.644A6.707 6.707 0 0 0 6 21.75a6.721 6.721 0 0 0 3.583-1.029c.774.182 1.584.279 2.417.279 5.322 0 9.75-3.97 9.75-9 0-5.03-4.428-9-9.75-9s-9.75 3.97-9.75 9c0 2.409 1.025 4.587 2.674 6.192.232.226.277.428.254.543a3.73 3.73 0 0 1-.814 1.686.75.75 0 0 0 .44 1.223ZM8.25 10.875a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25ZM10.875 12a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Zm4.875-1.125a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        ) : ''}

        <div style={{ position: 'fixed', bottom: '60px', right: '20px', display: isChatOpen ? 'block' : 'none' }}>
          <CustomChatbot key={language} refBot={refBot} isChatOpen={isChatOpen} setChatOpen={setChatOpen} />
        </div>

      </div>
    </div>
  );
}

export default App;
