import { random } from "../../utils/random";

export default {
    "title": "Поширені запитання",
    "search": "Пошук",
    "questions": [
        {
            title: 'Акредитація',
            questions: [
                {
                    id: random(1, 99999),
                    title: "Що таке акредитація ЗСУ?",
                    content: "Акредитація від Збройних Сил України дає право працівникам медіа виконувати редакційні завдання і здійснювати журналістську діяльність в районах ведення бойових дій та на військових об’єктах в тилу."
                },
                {
                    id: random(1, 99999),
                    title: "Для чого ЗСУ акредитують медіа?",
                    content: "Акредитуючи журналістів, Збройні Сили України створюють умови для донесення оперативної та об’єктивної інформації про російсько-українську війну. При цьому ЗСУ вживають заходів, необхідних для підтримання безпеки бойових операцій, збереження життя і здоров’я журналістів і військових."
                },
                {
                    id: random(1, 99999),
                    title: "Хто може отримати акредитацію?",
                    content: "Отримати акредитацію може представник медіа або блогер, зареєстрований як суб’єкт у сфері онлайн-медіа в порядку, передбаченому Законом України «Про медіа»."
                },
                {
                    id: random(1, 99999),
                    title: "Який термін дії акредитації?",
                    content: "Акредитація здійснюється на строк до 12 місяців."
                },
                {
                    id: random(1, 99999),
                    title: "Що відбувається після відправлення запиту на отримання акредитації?",
                    content: "Дані журналіста перевіряються відповідними державними установами України. За результатами перевірки працівнику медіа надається прескарта, або повідомляється про відмову в акредитації, разом з обґрунтуванням такого рішення."
                },
                {
                    id: random(1, 99999),
                    title: "Скільки часу треба очікувати рішення щодо акредитації?",
                    content: "За наявності необхідного пакету документів для акредитації відповідь може бути надано упродовж орієнтовно 10-15 днів. У разі необхідності додаткової перевірки відповідними державними органами, термін може бути подовжено."
                },
                {
                    id: random(1, 99999),
                    title: "Як можна перевірити статус акредитації?",
                    content: "Для перевірки статусу акредитації звертайтеся на <a href='mailto:newsmodu@gmail.com'>newsmodu@gmail.com</a> або за телефоном <a href='tel:+380894201864'>089-420-18-64</a>."
                },
                {
                    id: random(1, 99999),
                    title: "За яких умов представник медіа може бути позбавлений акредитації?",
                    content: "Підставою для припинення акредитації може бути відмова дотримуватися <a href='/rules'>Правил</a> роботи представника медіа в районі бойових дій  або їх порушення, а також передача прескарти іншій особі."
                },
                {
                    id: random(1, 99999),
                    title: "Що таке прескарта?",
                    content: "Це персоналізований документ встановленого зразка, який підтверджує наявність у представника медіа акредитації від ЗСУ. Надсилається Управлінням зв’язків з громадськістю Збройних Сил України в електронному форматі на email представника медіа після проходження їм процедури акредитації."
                },
                {
                    id: random(1, 99999),
                    title: "Як отримати акредитацію?",
                    content: "Заповніть форму за <a href='https://www.mil.gov.ua/content/zmi/acreditation_2024.docx'>посиланням</a> та надішліть на електронну адресу <a href='mailto:newsmodu@gmail.com'>newsmodu@gmail.com</a> копії таких документів: \n<ul> <li>•   ID-картка або 1-3 сторінки паспорта; <li>•    посвідчення представника медіа (не є обов’язковим для фіксерів / зареєстровані блогери подають копію підтверджуючого документу, виданого Національною радою України з питань телебачення і радіомовлення); <li>•   подання з клопотанням надати акредитацію; <li>•  фото особи високої роздільної здатності.</ul>"
                },
                {
                    id: random(1, 99999),
                    title: "Як діяти у випадку втрати прескарти?",
                    content: "Негайно зверніться за телефоном <a href='tel:+380894201864'>089-420-18-64</a> або напишіть на електронну адресу <a href='mailto:newsmodu@gmail.com'>newsmodu@gmail.com</a>, щоб отримати інструкції щодо подальших дій."
                }
            ]
        },
        {
            title: 'Запит на роботу в районах бойових дій',
            questions: [
                {
                    id: random(1, 99999),
                    title: "Куди подавати запит на роботу в районах бойових дій",
                    content: "Запит на роботу в зоні бойових дій надсилається органу зв’язків з громадськістю відповідного угрупування військ (дивіться у розділі «<a href='/contacts/'>Контакти</a>»)."
                },
                {
                    id: random(1, 99999),
                    title: "Навіщо подавати запит, якщо вже є акредитація?",
                    content: "Збройні Сили України мають переконатися, чи дозволяє оперативна обстановка в цьому районі роботу представників медіа."
                },
                {
                    id: random(1, 99999),
                    title: "Військовослужбовці ЗСУ запросили представників медіа до себе на позиції. Чи потрібна їм акредитація і запит на роботу в районі бойових дій?",
                    content: "Робота представників медіа в зоні бойових дій можлива лише за наявності акредитації від ЗСУ та погодження підрозділу зв’язків з громадськістю відповідного оперативно-стратегічного (оперативно-тактичного) угрупування військ.\nПрацюючи в районі бойових дій, представник медіа зобов’язаний дотримуватися <a href='/rules'>Правил роботи</a>."
                },
                {
                    id: random(1, 99999),
                    title: "Який термін подання запиту на роботу в районах бойових дій?",
                    content: "Запит слід надсилати не пізніше ніж за три робочі дні до запланованої дати відвідування."
                }
            ]
        },
        {
            title: 'Робота в районах бойових дій',
            questions: [
                {
                    id: random(1, 99999),
                    title: "В яких районах бойових дій можуть працювати медіа?",
                    content: "Командування оперативно-стратегічних угрупувань військ ЗСУ поділяють райони своєї відповідальності <strong>на зелену, жовту та червону зони</strong>.\n <ul> <li>•   У зеленій зоні медіа можуть працювати без супроводу пресофіцера або іншої посадової особи ЗСУ. <li>• У жовтій зоні медіа можуть працювати без супроводу лише в громадських місцях, до яких є вільний доступ цивільного населення. <li>•  Робота медіа у червоній зоні може бути дозволена, як виняток, за клопотанням Міністерства оборони України або Генерального штабу Збройних Сил України.</ul>"
                },
                {
                    id: random(1, 99999),
                    title: "Як дізнатися, до якої зони належить населений пункт, де я планую працювати?",
                    content: "Зверніться до Управління зв’язків з громадськістю Збройних Сил України або до пресцентру оперативно-стратегічного угрупування військ Збройних Сил України (розділ «Контакти»)."
                },
                {
                    id: random(1, 99999),
                    title: "Яку відповідальність несуть ЗСУ за життя і здоров’я представників медіа?",
                    content: "Збройні Сили України не несуть безпосередньої відповідальності за життя і здоров’я представників медіа. Однак у разі такої загрози Збройні Сили України  нададуть журналісту захист, екстрену медичну допомогу та евакуацію до медичного закладу."
                },
                {
                    id: random(1, 99999),
                    title: "Як регулюється робота медіа у районах бойових дій?",
                    content: "Для підтримання операційної безпеки посадові особи ЗСУ в окремих випадках можуть обмежувати фото- і відеозйомку. Підрозділи безпеки операцій також мають право попередньо переглядати відзняті фото- та відеоматеріали та просити видалити їх з носіїв даних у разі потреби.\nПід час роботи у червоній зоні представники медіа зобов’язуються надати зібрані фото- та відеоматеріали для перегляду на наявність інформації, що може становити інтерес для ворога, а також погодити їх поширення та оприлюднення з посадовою особою ЗСУ."
                },
                {
                    id: random(1, 99999),
                    title: "Яку відповідальність несуть представників медіа за розголошення таємної інформації?",
                    content: "Залежно від змісту поширеної інформації, мотивів та наслідків її поширення, можливе позбавлення волі від 3 до 12 років. У випадку встановлення в таких діях ознак державної зради, можливе довічне позбавлення волі з конфіскацією майна (статті 111 та 114-2 Кримінального кодексу України)."
                }
            ]
        }
    ]
}