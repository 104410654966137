import { useState, useEffect } from 'react';
import Hamburger from 'hamburger-react'
import { tabs } from './pages';
import { NavLink } from 'react-router-dom';
import { ReactComponent as MOD } from '../UMOD-UA-W-L02-RGB.svg';
import { ReactComponent as MODEN } from '../UMOD-EN-W-L02-RGB.svg';
import LanguageSwithcer from '../components/LanguageSwithcer';
import { useLanguage } from '../contexts/LanguageContext';
import { getLocalizedPath } from '../utils/getLocalizedPath';

const Header = ({ position }) => {
    const { language } = useLanguage();
    const [isOpened, setIsOpened] = useState(false);

    useEffect(() => {
        // block body scroll when menu is opened
        if (isOpened) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }


    }, [isOpened])

    const toggleMenu = () => {
        setIsOpened(!isOpened);
    }

    return (<div>
        <div className="bg-[#4D4634]">

            <div className='mx-2 md:mx-8 text-white flex justify-between items-center p-4'>
                {/* Logo section */}
                <div className="flex items-center">
                    <NavLink to={language === 'EN' ? '/en' : '/'} className='mr-4 flex gap-4'>
                        {language === 'EN' ? <MODEN className='h-auto w-[100px] md:w-[200px]' /> : <MOD className='h-auto w-[100px] md:w-[200px]' />}
                    </NavLink>
                </div>

                {/* Text section */}
                <div className="text-center block md:flex md:items-center md:gap-2 md:ml-auto">
                    {/* <p className="text-lg md:leading-none md:text-xl md:max-w-min md:text-right ">гаряча лінія Міноборони</p> */}
                    {/* <p className="text-lg md:text-5xl font-bold"><a className="text-white no-underline" href="tel:0123456789">012 345 6789</a></p> */}
                    <div className="hidden md:block"><LanguageSwithcer /></div>

                </div>

                <div className='relative'>
                    <div className="h-10 w-10 text-[#f29104] cursor-pointer flex items-center">
                        <Hamburger toggled={isOpened} toggle={setIsOpened} />
                    </div>
                    <nav className={`fixed  md:absolute md:w-max bg-[#4D4634] pt-8 pb-2 right-0 shadow-md text-center  transform transition-all duration-500 ${isOpened ? 'translate-y-0' : '-translate-y-full'} ${isOpened ? 'top-0 bottom-0 w-full md:bottom-auto md:mt-0 md:top-[100%]' : 'w-full top-[-100%] mt-0'}`}
                    >

                        <div className='flex md:hidden mb-6 mx-8'>
                            <div className="flex items-center">
                                <NavLink to={language === 'EN' ? '/en' : '/'} className='mr-4 flex gap-4'>
                                    {language === 'EN' ? <MODEN className='h-auto w-[100px] md:w-[200px]' /> : <MOD className='h-auto w-[100px] md:w-[200px]' />}
                                </NavLink>
                            </div>

                            <div className="block md:hidden"><LanguageSwithcer /></div>

                            <div className="h-10 w-10 text-[#f29104] cursor-pointer ml-auto">
                                <Hamburger toggled={isOpened} toggle={setIsOpened} />
                            </div>
                        </div>

                        <ul>

                            {tabs.map((tab, index) => (<div className='mb-4' key={index} onClick={toggleMenu}>
                                {(tab.shudeShow !== false) && <li>
                                    <NavLink to={getLocalizedPath(tab.path, language)} className='p-2 font-bold text-gray-400 hover:text-gray-300 text-xl md:text-2xl'>{tab.groupName[language]}</NavLink>
                                </li>}
                                {
                                    tab?.links && tab.links.map((link, index) => (
                                        <li key={index}>
                                            <NavLink to={getLocalizedPath(link.path, language)} className='block px-8 hover:text font-normal hover:text-gray-200 text-lg md:text-2xl'>{link.name[language]}</NavLink>
                                        </li>
                                    ))
                                }
                            </div>))}
                        </ul>
                    </nav>
                </div>

            </div>
        </div>
        {/* <Poster /> */}

    </div>
    );
}

export default Header;