
export default {
    mediaDutiesTitle: "RESPONSIBILITIES OF MEDIA REPRESENTATIVES",
    mediaDuties: [
        "carry and present the press card and identity documents;",
        "in the area of combat operations, move only within the time frame and along the route agreed with the Armed Forces;",
        "carry personal protective equipment: protective helmet, body armour marked ПРЕСА/PRESS;",
        "carry a medical kit and know how to use it;",
        "wear and display media identification markers on oneself and on transportation, except when it may endanger life and health;",
        "not wear military uniforms or insignia of the Armed Forces, other defence forces, or handle weapons and ammunition;",
        "upon request, hand over storage devices with photo and video materials to the authorized person for verification of restricted information; delete such information from the device if found."
    ],
    mediaProhibitedTitle: "Information Prohibited from Disclosure to Media Representatives During Work in Combat Zones Includes:",
    mediaProhibited: [
        "Article 17 of the <a href='https://zakon.rada.gov.ua/laws/show/638-15#Text' target='_new'>Ukrainian Law on Counteracting Terrorism</a>;",
        "<a href='https://zakon.rada.gov.ua/laws/show/z0052-21#Text' target='_new'>Order of the Security Service of Ukraine dated 23 December 2020 No.383</a>;",
        "<a href='https://zakon.rada.gov.ua/rada/show/v0605322-23#Text' target='_new'>Order of the Ministry of Defence of Ukraine dated 17 October 2023 No.605</a>;",
        "Order of the General Staff of the Armed Forces of Ukraine dated 30 January 2023 No.12;",
        "list of information, disclosure of which may result in the enemy's awareness of actions of the Armed Forces of Ukraine and other defence forces:",
        "names of military units and formations of the Armed Forces of Ukraine; coordinates of locations; personnel strength; quantity, storage locations, technical condition of armaments and military equipment;",
        "topics, images, and symbols identifying military objects;",
        "regarding military operations conducted or planned;",
        "regarding the security and defence system;",
        "procedure for engaging forces and assets to perform combat missions;",
        "collection of intelligence data: methods, means, forces, and assets involved;",
        "movement and deployment of Ukrainian forces: names, quantity, locations, areas, routes of movement;",
        "conduct of unique operations specifying techniques and methods used;",
        "effectiveness of enemy electronic warfare forces and assets;",
        "cancelled, pending, or during preparation stage operations of Ukrainian military;",
        "lost or downed aircraft, aerial vehicles, missing ships, and planned or ongoing search and rescue operations;",
        "planning and conduct of security measures for military operations: disinformation, simulation, demonstrations, camouflage, counter-technical reconnaissance, and information protection;",
        "losses of military equipment and personnel if such information was not publicly disclosed by the General Staff of the Armed Forces of Ukraine;",
        "photos and videos featuring mutual recognition signs, aircraft markings, and state registration marks on military equipment, targeting devices, combat control, and reconnaissance, as well as structural features on military equipment;",
        "photos and videos from inside tanks, self-propelled artillery units, anti-aircraft missile complexes, armoured combat vehicles, and tactical aircraft;",
        "information about delivery terms, types, quantity, material-technical support, storage locations of armaments and military equipment provided as assistance from partner countries, if such information was not published on the official websites of the Ministry of Defence of Ukraine and the General Staff of the Armed Forces of Ukraine or the departments of partner countries;",
        "information on conducted informational and psychological operations;",
        "It is strictly prohibited to broadcast live the work of air defence, publish precise missile impact addresses, use car dash cameras, and publish photos and videos taken by dash cameras."
    ]

}