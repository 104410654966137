import Main from '../pages/Main';
import FAQWrap from '../pages/FAQ';
import Contacts from '../pages/Contacts';
import WorkingRules from '../pages/WorkingRules';
import ApplicationForm from '../pages/ApplicationForm';
import Complaint from '../pages/Complaint';

export const tabs = [
    {
        groupName: { UA: 'Взаємодія з медіа', EN: 'Media Relations' },
        path: '/',
        Component: <Main />,
        shudeShow: false,
        links: [
            { name: { UA: 'Шляхи взаємодії', EN: 'Pathways of Cooperation' }, path: '/:lang?/home', Component: <Main /> },
            { name: { UA: 'Питання та відповіді', EN: 'Questions and Answers' }, path: '/:lang?/faq', Component: <FAQWrap /> },
            { name: { UA: 'Правила роботи', EN: 'Work Rules' }, path: '/:lang?/rules', Component: <WorkingRules /> },
            { name: { UA: 'Контакти', EN: 'Contacts' }, path: '/:lang?/contacts', Component: <Contacts /> },
            { name: { UA: 'Отримати акредитацію', EN: 'Get accreditation' }, path: '/:lang?/application', Component: <ApplicationForm /> },
            { name: { UA: 'Подати скаргу', EN: 'Submit a Complaint' }, path: '/:lang?/complaint', Component: <Complaint /> },
        ],
    }
];


export const tabs2 = [
    {
        groupName: { UA: 'Взаємодія з медіа', EN: 'Media Relations' },
        path: '/:lang?/',
        Component: <Main />,
        shudeShow: false,
        links: {
            UA: [
                { name: 'Шляхи взаємодії', path: '/home', Component: <Main /> },
                { name: 'Питання та відповіді', path: '/faq', Component: <FAQWrap /> },
                { name: 'Правила роботи', path: '/rules', Component: <WorkingRules /> },
                { name: 'Контакти', path: '/contacts', Component: <Contacts /> },
                { name: 'Отримати акредитацію', path: '/application', Component: <ApplicationForm /> },
                { name: 'Подати скаргу', path: '/complaint', Component: <Complaint /> },
            ],
            EN: [
                { name: 'Pathways of Cooperation', path: '/en/home', Component: <Main /> },
                { name: 'Questions and Answers', path: '/en/faq', Component: <FAQWrap /> },
                { name: 'Work Rules', path: '/en/rules', Component: <WorkingRules /> },
                { name: 'Contacts', path: '/en/contacts', Component: <Contacts /> },
                { name: 'Get accreditation', path: '/en/application', Component: <ApplicationForm /> },
                { name: 'Submit a Complaint', path: '/en/complaint', Component: <Complaint /> },
            ]
        },
    }
];