

const Poster = ({ poster = '/media/images/bg2.png', title = 'Взаємодія з\n медіа' }) => {

    return <div className="bg-center bg-cover bg-no-repeat" style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + poster})`,
        backgroundposition: 'right 0 center'
    }}>
        <div className='container max-w-7xl mx-auto  items-center p-4'>
            <h1 className="text-3xl md:text-7xl leading-[1.5em] my-12 text-white whitespace-pre-line uppercase">{title}</h1>
        </div>
    </div>
}

export default Poster;