import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { tabs } from './pages';
import { getLocalizedPath } from '../utils/getLocalizedPath';

const Navigation = () => {
    const location = useLocation();
    const { language } = useLanguage();

    const currentGroupLinks = tabs.find(group =>
        (group.links && group.links.some(link => {

            return link.path === '/:lang?' + location.pathname.replace(/\/en/, '')
        }
        )) || group.path === '/:lang?' + location.pathname.replace(/\/en/, '')
    )?.links;

    if (!currentGroupLinks) return null;

    return <>
        <div className="block md:flex md:flex-wrap">
            {currentGroupLinks.map((tab, index) => (
                <NavLink
                    key={index}
                    to={getLocalizedPath(tab.path, language)}
                    className={({ isActive }) =>
                        `w-full block md:flex md:items-center md:justify-center py-2 px-4 text-sm font-medium text-center hover:bg-opacity-80 transition-colors duration-150 ${isActive ? 'bg-white text-gray-900' : 'bg-[#4D4634] text-gray-300 '
                        }`}
                    style={({ isActive }) => ({
                        flex: 1,
                        padding: '16px',
                        textAlign: 'center',
                        textDecoration: 'none',
                        fontWeight: 'medium',
                        fontSize: '18px', // This is equivalent to text-sm in Tailwind CSS
                        transition: 'color 0.15s ease-in-out'
                    })}
                >
                    {tab.name[language]}
                </NavLink>
            ))}
        </div>
    </>
}

export default Navigation;