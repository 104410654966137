// Определяем функцию для импорта всех файлов из заданной директории
function importAll(r) {
  // Итерируемся по всем найденным файлам и собираем их экспорты в один объект
  return r.keys().reduce((acc, key) => {
    // Преобразуем './filename.js' в 'filename'
    const moduleName = key.replace('./', '').replace('.js', '');
    // Собираем все экспорты в аккумулирующий объект
    acc[moduleName] = r(key).default;
    return acc;
  }, {});
}

// Импортируем все JS файлы из директорий 'en' и 'ua'
const enTranslations = importAll(require.context('./en', false, /\.js$/));
const uaTranslations = importAll(require.context('./ua', false, /\.js$/));

// Строим объект переводов, где ключи соответствуют языкам
const translations = {
  EN: enTranslations,
  UA: uaTranslations
};

const getNestedProperty = (obj, path, defaultValue = null) => {
  return path.split('.').reduce((xs, x) => (xs && xs[x] !== undefined ? xs[x] : defaultValue), obj);
};

// Функция обертка для получения перевода с фоллбеком на украинский язык
export const getTranslation = (path, language) => {
  const value = getNestedProperty(translations[language], path);

  // Если значение не найдено, используем украинский язык как фоллбек
  return value !== null ? value : getNestedProperty(translations['UA'], path);
};

export default translations;
