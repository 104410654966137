
import React from "react"
import { useLanguage } from '../contexts/LanguageContext'
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { tabs } from '../layout/pages';
import { getLocalizedPath } from '../utils/getLocalizedPath';

export default function Main() {
    const { getTranslation, language } = useLanguage();
    const t = getTranslation('Main');
    

    const btnTab = tabs[0].links.find(item => item.path === '/:lang?/application')

    return <div className="container max-w-7xl mx-auto text-white items-center p-4">
        <h1 className="text-3xl md:text-5xl leading-[1.5em] my-12 text-gray-900 font-bold uppercase">{t.title}</h1>
        <p className="text-gray-800" dangerouslySetInnerHTML={{ __html: t.description }}></p>
        {t.DesctopSchema && <t.DesctopSchema className="hidden md:block w-full my-4 mx-auto h-auto my-8" />}
        {t.MobileSchema && <t.MobileSchema className="hold-iframe md:hidden flex justify-center w-full h-auto" />}

        <NavLink
            to={getLocalizedPath(btnTab.path, language)}
            className={({ isActive }) =>
                `w-fit mx-auto block md:flex md:items-center md:justify-center py-2 px-4 text-sm font-medium text-center hover:bg-opacity-80 transition-colors duration-150 ${isActive ? 'bg-white text-gray-900' : 'bg-[#4D4634] text-gray-300 '
                }`}
            style={({ isActive }) => ({
                flex: 1,
                padding: '16px',
                textAlign: 'center',
                textDecoration: 'none',
                fontWeight: 'medium',
                fontSize: '18px', // This is equivalent to text-sm in Tailwind CSS
                transition: 'color 0.15s ease-in-out'
            })}
        >
            {btnTab.name[language]}
        </NavLink>
    </div>
}

