import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ChatBot from 'react-simple-chatbot';
import parse from 'html-react-parser';
import { ThemeProvider } from 'styled-components';
import { useLanguage } from './contexts/LanguageContext';
import ApplicationForm from './pages/ApplicationForm';

const theme = {
  background: '#fff',
  headerBgColor: 'rgb(77 70 52 / 1)',
  headerFontColor: '#fff',
  headerFontSize: '16px',
  botBubbleColor: '#EF6C00',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};

const RedirectStep = ({ to }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to);
  }, []);

  return null;
};


const LinkOption = ({ label, to }) => {
  const handleClick = () => {
    window.location.href = to;
  };

  return (
    <button onClick={handleClick}>
      {label}
    </button>
  );
};

// Пользовательский компонент для отображения сообщения
const CustomMessage = ({ title, content, previousStep }) => {

  return <div className='bg-[#4D4634] text-white p-2 rounded-t-xl rounded-b-xl rounded-bl-none w-full' >
    <div className='font-bold mb-2'>{title}</div>
    <div>
      {parse(content)}
    </div>
  </div>
}

const CustomMessageRegion = ({ previousStep }) => {
  const { emails, osuv, region } = previousStep.metadata;
  const { language } = useLanguage();
  return (
    <div className='bg-[#4D4634] text-white p-2 rounded-t-xl rounded-b-xl rounded-bl-none w-full' >
      <h3 className='font-bold mb-2 text-white'>
        {language === 'EN' ? 'Contact the public relations service for approval' : 'Зверніться за погодженням до служби зв’язків з громадськістю'}
        {osuv}</h3>
      <p>
        {emails && emails.length > 0 && emails.map((email, index) => (
          <span key={index}>
            <a href={`mailto:${email}`}>{email}</a><br />
          </span>
        ))}
      </p>
      <p className='mt-4'>
        <a href="/rules" target="_blank">
          {language === 'EN' ? 'Rules of work' : 'Правила роботи'}</a><br />
        <a href="/faq" target="_blank">
          {language === 'EN' ? 'Frequently asked questions' : 'Поширені запитання'}</a>
      </p>
    </div>
  );
};

export const MyForm = () => {
  const { getTranslation } = useLanguage();
  const t_form = getTranslation('Form');

  const [files, setFiles] = useState({
    docFile: null,
    idScan: null,
    passportScans: [],
    mediaID: null,
    letter: null,
    photo: null
  });

  const [activeTab, setActiveTab] = useState('idScan'); // Добавляем состояние для управления активным выбором
  const [isLoading, setIsLoading] = useState(false);

  const onFileAccepted = (file, name) => {
    setFiles({ ...files, [name]: file });
  }

  const formSubmit = async (e) => {

    e.preventDefault();
    // Проверяем, что все обязательные поля заполнены
    if (!files.docFile || !files.mediaID || !files.letter || !files.photo) {
      alert(t_form.requiredFields);
      return;
    }

    // Проверяем, что заполнено только одно из полей: idScan или passportScans
    if ((files.idScan && files.passportScans.length > 0) || (!files.idScan && files.passportScans.length === 0)) {
      alert(t_form.scanOrId);
      return;
    }
    try {
      setIsLoading(true);

      const formData = new FormData();
      for (const key in files) {
        if (Array.isArray(files[key])) {
          files[key].forEach(file => {
            formData.append(key, file);
          });
        } else {
          formData.append(key, files[key]);
        }
      }

      const response = await fetch('/actions/formWIthFiles.php', {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        alert(t_form.documentsSubmitted);
      } else {
        alert(t_form.error);
      }
    } catch (error) {
      alert(t_form.error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <form onSubmit={formSubmit} className='flex flex-col gap-2 bg-[#4D4634] text-white p-2 rounded-t-xl rounded-b-xl rounded-bl-none w-full'>
      <label className="font-bold" htmlFor="docFile">{parse(t_form.docFile)}:</label>
      <input required className='border border-[#f29104] rounded-lg p-4 border-dashed' type="file" accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" id="docFile" onChange={(e) => onFileAccepted(e.target.files[0], 'docFile')} />

      <label className="font-bold" htmlFor="mediaID">{t_form.mediaID}:</label>
      <input required className='border border-[#f29104] rounded-lg p-4 border-dashed' type="file" accept="image/png, image/jpeg" id="mediaID" onChange={(e) => onFileAccepted(e.target.files[0], 'mediaID')} />

      <label className="font-bold" htmlFor="letter">{t_form.letter}:</label>
      <input required className='border border-[#f29104] rounded-lg p-4 border-dashed' type="file" accept="image/png, image/jpeg" id="letter" onChange={(e) => onFileAccepted(e.target.files[0], 'letter')} />

      <label className="font-bold" htmlFor="photo">{t_form.photo}:</label>
      <input required className='border border-[#f29104] rounded-lg p-4 border-dashed' type="file" accept="image/png, image/jpeg" id="photo" onChange={(e) => onFileAccepted(e.target.files[0], 'photo')} />

      <div>
        <div className="tabs flex flex-col mb-2 text-white font-bold">
          *<button className={`flex gap-1 font-bold rounded hover:bg-opacity-80  mr-2 ${activeTab === 'idScan' ? 'underline ' : ' '}`} type="button" onClick={() => setActiveTab('idScan')}>
            {activeTab === 'idScan' ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
              : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <circle cx="12" cy="12" r="9" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            }
            {t_form.idScan}
          </button>
          <button className={`flex gap-1 font-bold rounded hover:bg-opacity-80 ${activeTab === 'passportScans' ? 'underline' : ''}`} type="button" onClick={() => setActiveTab('passportScans')}>
            {activeTab === 'passportScans' ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
              : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <circle cx="12" cy="12" r="9" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            }
            {t_form.passportScans}</button>
        </div>
        {activeTab === 'idScan' && (<>
          <input className='w-full border border-[#f29104] rounded-lg p-4 border-dashed' type="file" accept="image/png, image/jpeg" id="idScan" onChange={(e) => onFileAccepted(e.target.files[0], 'idScan')} />
        </>)}
        {activeTab === 'passportScans' && (<>
          <input className='w-full border border-[#f29104] rounded-lg p-4 border-dashed' type="file" accept="image/png, image/jpeg" id="passportScans" multiple onChange={(e) => onFileAccepted(e.target.files, 'passportScans')} />
        </>)}
      </div>

      <button className='bg-[#f29104] text-white p-2 rounded-lg mt-4' type="submit">{t_form.submit} {isLoading && <div
        className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
      >
      </div>}</button>
    </form>
  );
}

const CustomMessageContacts = ({ previousStep }) => {
  const { emails, dependens } = previousStep.metadata;

  return (
    <div className='bg-[#4D4634] text-white p-2 rounded-t-xl rounded-b-xl rounded-bl-none w-full' >
      <h3 className='font-bold mb-2 text-white'>{dependens}</h3>
      <p>
        {emails && emails.length > 0 && emails.map((email, index) => (
          <span key={index}>
            <a href={`mailto:${email}`}>{email}</a><br />
          </span>
        ))}
      </p>
    </div>
  );
}

function CustomChatbot({ refBot, setChatOpen }) {
  const { language, getTranslation } = useLanguage();
  const t = getTranslation('ChatBot');
  const t_form = getTranslation('Form');
  const [content, setContent] = useState(t.content);
  const [steps, setSteps] = useState([]);
  const chatBotRef = React.createRef(null);

  useEffect(() => {
    setContent(getTranslation('ChatBot').content);
    setSteps(Object.entries(getTranslation('ChatBot').content).map(createStep));
  }, [language]);

  const handleEnd = (event, steps) => {
    // steps содержит информацию о завершенном шаге
    console.log('Step completed:', steps);
  };


  // Функция для создания шага на основе данных перевода
  const createStep = ([id, data]) => {
    switch (data.type) {
      case 'message':
        return {
          id,
          component: <CustomMessage title={data.title} content={data.content} />,
          trigger: data.trigger
        };
      case 'options':
        return {
          id,
          options: [...data.options, { value: 'to_start', label: t.back, trigger: 'start' }],
        };
      default:
        return { id };
    }
  };

  // Создаем массив шагов
  // const steps = Object.entries(content).map(createStep);


  // console.log(chatBotRef);
  return (
    <ThemeProvider theme={theme}>
      {steps.length &&
        <ChatBot
          supportsScrollBehavior={true}
          ref={chatBotRef}
          handleEnd={handleEnd}
          headerTitle={t.title}
          avatarStyle={{ display: 'none' }}
          steps={[
            ...steps,
            {
              id: 'submit-complaint',
              component: <RedirectStep to="/complaint" />,
              trigger: 'options-1'
            },
            {
              id: 'region-info',
              component: (
                <CustomMessageRegion />
              ),
              trigger: 'region-options'
            },
            {
              id: 'contacts-info',
              component: <CustomMessageContacts />,
              trigger: 'contacts-options'
            },
            {
              id: 'submit-documents',
              title: t_form.title,
              trigger: 'contacts-options',
              component: <MyForm />
            },
          ]}
          cache={true}
          bubbleOptionStyle={{ fontSize: '14px', background: 'initial', color: '#f29104', padding: '6px 8px', border: '1px solid #f29104' }}
          bubbleStyle={{ fontSize: '14px', background: '#4D4634', color: '#fff' }}
          footerStyle={{ display: 'none' }}
          userDelay={500}
          botDelay={500}
        />}
      {/* close btn */}
      <div className="absolute top-0 right-0 mt-2 mr-2 z-[9999] ">
        <button className="bg-[#4D4634] text-white p-2 rounded-full hover:rotate-[180deg] duration-1000" onClick={() => { setChatOpen(false) }}>
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M6 18L18 6M6 6l12 12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
        </button>
      </div>
    </ThemeProvider>
  );
}

export default CustomChatbot;
