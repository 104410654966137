export default {
    title: "Error",
    complaint: 'Complaint text (required)',
    fullname: "Your name (required)",
    // Fill in all required fields
    requiredFields: 'Fill in all required fields',
    // Documents submitted successfully
    documentsSubmitted: 'Documents submitted successfully',
    remove: 'Remove',
    download: 'Download',
    //     emailPlaceholder: 'Адреса вашого E-Mail',
    emailPlaceholder: 'Your E-Mail address',
    //addPlaceholder: 'Додайте файли (опц.)',
    addPlaceholder: 'Add files (optional)',
    docFile: '*Form file (<a href="https://www.mil.gov.ua/content/zmi/Application_Form_2023.docx" target="_new">download</a> sample)',
    idScan: 'ID card scan',
    passportScans: 'Passport scans',
    mediaID: '*Media representative ID scan',
    letter: '*Letter of application scan from media manager',
    photo: '*Passport-sized photo scan',
    submit: 'Submit',
    fullNameRequired: 'Enter your name',
    phoneRequired: 'Enter your phone',
    complaintRequired: 'Enter the text of the complaint',
    // Drag and drop files here or click here to select
    dropFiles: 'Drag and drop files here or click here to select',
    or: 'or',
    // ur_phone: "Ваш телефон (опц.)",
    ur_phone: "Your phone (optional)",
    confirmEmail: 'To submit the complaint, confirm your email',
    // Enter code
    enterCode: 'Enter code',
    // Get code
    getCode: 'Get code',
    // Code has been sent to your email
    codeSent: 'Code has been sent to your email',
    // Confirm
    confirm: 'Confirm',
    // Next step
    next: 'Next step',
    // Change email
    changeEmail: 'Change email',
    // Step
    step: 'Step',
    // of
    of: 'of',
    //Невірний код підтвердження
    wrongCode: 'Wrong confirmation code',
    // Back
    back: 'Prev step',
    recaptchaError: 'reCAPTCHA verification failed. Please try again.',
    // Send
    send: 'Send',
    //    complaintSubmitted: 'Дякуємо, вашу скаргу надіслано',
    complaintSubmitted: 'Thank you, your complaint has been sent',
    // form in bot
    title: 'Submit documents for accreditation in the Armed Forces of Ukraine',
    scanOrId: 'Please upload or scan your ID card or passport scans.',
}