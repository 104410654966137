import React from "react"
import { useLanguage } from '../contexts/LanguageContext'

export default function WorkingRules() {
    const { getTranslation } = useLanguage();
    const t = getTranslation('WorkingRules');

    // const t = translations.WorkingRules;
    return <div className="useful-contacts container max-w-7xl mx-auto text-gray-900 items-center p-4">
        <div className="et_pb_text_inner">
            <h2 className="mt-8">{t.mediaDutiesTitle}</h2>
            <ul className="list-disc ml-4 pl-4 ">
                {t.mediaDuties.map((item, index) => <li key={index} dangerouslySetInnerHTML={{ __html: item }} />)}
            </ul>

            <h2 className="mt-8 uppercase">{t.mediaProhibitedTitle}</h2>
            <ul className="list-disc ml-4 pl-4 ">
                {t.mediaProhibited.map((item, index) => <li key={index} dangerouslySetInnerHTML={{ __html: item }} />)}
            </ul>
        </div>
    </div>

}