import React, { useState } from 'react'
import { useLanguage } from '../contexts/LanguageContext'

const LanguageSwithcer = () => {
    const { language, toggleLanguage } = useLanguage();

    const isChecked = language === 'EN';

    return (
        <>
            <label className='themeSwitcherThree relative inline-flex cursor-pointer select-none items-center'>
                <input
                    type='checkbox'
                    checked={isChecked}
                    onChange={toggleLanguage}
                    className='sr-only'
                />
                <div className='shadow-card flex h-auto py-2 w-[62px] items-center justify-center rounded-md'>
                    <span
                        className={`flex bg-[#f29104] h-auto w-1/2 items-center justify-center rounded-l ${!isChecked ? 'bg-opacity-100 text-white' : 'bg-opacity-30 text-gray-900'
                            }`}
                    >
                        UA
                    </span>
                    <span
                        className={`flex bg-[#f29104] h-auto w-1/2 items-center justify-center rounded-r ${isChecked ? 'bg-opacity-100 text-white' : 'bg-opacity-30 text-gray-900'
                            }`}
                    >
                        EN
                    </span>
                </div>
            </label>
        </>
    )
}

export default LanguageSwithcer
