import React, { useState, useCallback, useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext'
import { useDropzone } from 'react-dropzone';
import useAlert from '../utils/Alert';
import parse from 'html-react-parser';
import { validateEmail } from '../utils/emailValidator';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function FileDropzone({ accept, onFileAccepted, multiple }) {
    const { getTranslation } = useLanguage();
    const t = getTranslation('Form');

    const [files, setFiles] = useState([]);

    const onDrop = useCallback(acceptedFiles => {
        const mappedFiles = acceptedFiles.map(file =>
            Object.assign(file, {
                preview: file.type.startsWith('image/') ? URL.createObjectURL(file) : null,
            })
        );

        setFiles(mappedFiles);
        onFileAccepted(multiple ? mappedFiles : mappedFiles[0]);

    }, [onFileAccepted, multiple]);

    const thumbs = files.map(file => (
        <div key={file.name} className="inline-flex flex-col items-center justify-center p-2">
            {file.preview ? (
                <img src={file.preview} alt={file.name} className="h-20 w-20 object-cover" />
            ) : (
                <div className="text-sm text-gray-600">{file.name}</div>
            )}
            <div className="text-xs text-center mt-1">{file.name}</div>
        </div>
    ));

    const { getRootProps, getInputProps, fileRejections, acceptedFiles, isDragActive } = useDropzone({ onDrop, accept, multiple });

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <ul >
            {errors.map(e => (
                <li className='text-red-500' key={e.code}>{e.message}</li>
            ))}
        </ul>
    ));

    return (
        <section className="container">
            <div {...getRootProps({ className: `dropzone p-4 border-dashed border-2 ${isDragActive ? 'border-blue-500' : 'border-gray-300'} text-center ${fileRejections.length > 0 ? 'border-red-500' : ''}` })}>
                <input {...getInputProps()} />
                <p>{t.dropFiles}</p>
            </div>
            <aside className="flex flex-row flex-wrap mt-4">
                {thumbs}
            </aside>
            {fileRejections.length > 0 && fileRejectionItems}
        </section>
    );
}
const code = '1111';

function ApplicationForm() {
    const { message, showAlert, hideAlert } = useAlert();
    const { getTranslation } = useLanguage();
    const t = getTranslation('Form');
    const [step, setStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [docFile, setDocFile] = useState(null);
    const [idScan, setIdScan] = useState(null);
    const [passportScans, setPassportScans] = useState([]);
    const [mediaID, setMediaID] = useState(null);
    const [letter, setLetter] = useState(null);
    const [photo, setPhoto] = useState(null);
    const [activeTab, setActiveTab] = useState('idScan');
    // Verification
    const [email, setEmail] = useState('');
    const [fixedEmail, setFixedEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [isVerified, setIsVerified] = useState(false);

    const { executeRecaptcha } = useGoogleReCaptcha();

    const isFormValid = () => {
        // Проверка всех обязательных полей и логика валидации
        return docFile && ((idScan && passportScans.length === 0) || (!idScan && passportScans.length)) && mediaID && letter && photo;
    };

    const handleNextStep = () => {
        setStep(prevStep => prevStep + 1);
    };

    const handlePrevStep = () => {
        if (step > 2 && step < 4 && isVerified) {
            return
        }

        setStep(prevStep => prevStep - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isLoading) return;

        if (!isFormValid()) {
            showAlert(t.requiredFields);
            return;
        }

        if (!executeRecaptcha) {
            showAlert(t.recaptchaError);
            return;
        }

        setIsLoading(true);

        try {
            const formData = new FormData();
            const token = await executeRecaptcha('submit');

            formData.append('docFile', docFile);

            if (activeTab === 'idScan' && idScan) {
                formData.append('idScan', idScan);
            } else if (activeTab === 'passportScans') {
                passportScans.forEach((file, index) => formData.append(`passportScan${index}`, file));
            }

            formData.append('mediaID', mediaID);
            formData.append('letter', letter);
            formData.append('photo', photo);
            formData.append('g-recaptcha-response', token);


            const response = await fetch('/actions/formWIthFiles.php', {
                method: 'POST',
                body: formData
            });
            const data = await response.json();
            if (data.status === 'success') {
                showAlert(t.documentsSubmitted);
            } else {
                showAlert(data.message);
            }
        } catch (error) {
            showAlert(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFixedEmail = () => {
        setFixedEmail(email);
    }

    const handleVerifyEmail = () => {
        if (verificationCode !== code) {
            showAlert(t.wrongCode);
            return;
        }

        setIsVerified(true);
        handleNextStep();

    }

    // return obj with status & message
    const stepValidate = (step) => {
        switch (step) {
            case 0:
                return { status: true, message: '' };
            case 1:
                if (!email || !validateEmail(email)) {
                    return { status: false, message: t.emailRequired };
                }
                return { status: true, message: '' };
            case 2:
                if (!verificationCode) {
                    return { status: false, message: t.codeRequired };
                }
                return { status: true, message: '' };
            case 3:
                if (!docFile) {
                    return { status: false, message: t.docFileRequired };
                }
                return { status: true, message: '' };
            case 4:
                if (!idScan && passportScans.length === 0) {
                    return { status: false, message: t.scanOrIdRequired };
                }
                return { status: true, message: '' };
            case 5:
                return { status: true, message: '' };
            case 6:
                if (!letter) {
                    return { status: false, message: t.letterRequired };
                }
                return { status: true, message: '' };
            case 7:
                if (!photo) {
                    return { status: false, message: t.photoRequired };
                }
                return { status: true, message: '' };
            default:
                return { status: true, message: '' };
        }
    }

    const renderStep = () => {
        switch (step) {
            case 0:
                return (<div className="max-w-3xl">
                    <h1 className="text-xl font-bold  mb-4">{t.description.title}</h1>
                    <ul className="list-disc list-inside  space-y-2">
                        {t.description.list.map((item, index) => <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>)}
                    </ul>
                    <p className=" mt-4">
                        {t.description.footer}
                    </p>
                    <div className='flex justify-between'>
                        <button onClick={listener} className='ml-auto bg-[#4D4634] text-white px-4 py-2 rounded hover:bg-opacity-80'>{t.next}</button>
                    </div>
                </div>);

            case 1:
                return (
                    <div className='w-full'>
                        <div className='flex flex-col'>
                            <p className="block mb-2 font-bold">{t.confirmEmail}</p>
                            <input type="email" name="email" value={email} onChange={e => { setEmail(e.target.value) }} placeholder={t.emailPlaceholder} className='border border-gray-300 p-2 rounded mb-4' />
                            <div className='flex justify-between'>
                                <button onClick={listener} disabled={!!!email || !validateEmail(email)} className={`ml-auto bg-[#4D4634] text-white px-4 py-2 rounded hover:bg-opacity-80 ${!!email && validateEmail(email) ? '' : 'bg-opacity-50 cursor-no-drop'}`}>{t.getCode}</button>
                            </div>
                        </div>
                    </div>
                );
            case 2:
                return (<div className='w-full min-w-[320px]'>
                    <div className='flex flex-col '>
                        <p className="block mb-2 font-bold">{t.enterCode}<br />
                            {t.codeSent} <b>{fixedEmail}</b></p>
                        <input type="text" name="verificationCode" value={verificationCode} onChange={e => setVerificationCode(e.target.value)} placeholder={t.codePlaceholder} className='border border-gray-300 p-2 rounded mb-4' />
                        <div className='flex justify-between'>
                            <button onClick={listener} className='ml-auto bg-[#4D4634] text-white px-4 py-2 rounded hover:bg-opacity-80'>{t.confirm}</button>
                        </div>
                    </div>
                </div>
                );
            case 3:
                return (<div>
                    <label className="block mb-2 font-bold">{parse(t.docFile)}:</label>
                    <FileDropzone
                        accept={{ 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [] }}
                        onFileAccepted={setDocFile} />

                    <div className='flex justify-between'>
                        <button onClick={listener} className='ml-auto bg-[#4D4634] text-white px-4 py-2 rounded hover:bg-opacity-80'>{t.next}</button>
                    </div>
                </div>);

            case 4:
                return (<div>
                    <div className="tabs flex mb-2 text-[#4D4634] font-bold">
                        *<button className={`flex gap-1 font-bold rounded hover:bg-opacity-80  mr-2 ${activeTab === 'idScan' ? 'underline ' : ' '}`} type="button" onClick={() => setActiveTab('idScan')}>
                            {activeTab === 'idScan' ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                                : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <circle cx="12" cy="12" r="9" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            }
                            {t.idScan}
                        </button>
                        <span className="mr-2">{t.or}</span>
                        <button className={`flex gap-1 font-bold rounded hover:bg-opacity-80 ${activeTab === 'passportScans' ? 'underline text-[#4D4634]' : ''}`} type="button" onClick={() => setActiveTab('passportScans')}>
                            {activeTab === 'passportScans' ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                                : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <circle cx="12" cy="12" r="9" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            }
                            {t.passportScans}</button>
                    </div>
                    {activeTab === 'idScan' && (
                        <div>
                            <FileDropzone
                                accept={{
                                    'image/jpeg': [],
                                    'image/png': []
                                }}
                                onFileAccepted={setIdScan} />
                        </div>)}
                    {activeTab === 'passportScans' && (
                        <div>
                            <FileDropzone
                                accept={{ 'image/jpeg': [], 'image/png': [] }}
                                onFileAccepted={setPassportScans} multiple={true} />
                        </div>)}
                    {/* prev step */}
                    <div className='flex justify-between'>
                        <button onClick={handlePrevStep} className='bg-[#4D4634] text-white px-4 py-2 rounded hover:bg-opacity-80'>{t.back}</button>
                        <button onClick={listener} className='bg-[#4D4634] text-white px-4 py-2 rounded hover:bg-opacity-80'>{t.next}</button>
                    </div>
                </div>);

            case 5:
                return (<div>
                    <label className="block mb-2 font-bold">{t.mediaID}:</label>
                    <FileDropzone
                        accept={{ 'image/jpeg': [], 'image/png': [] }}
                        onFileAccepted={setMediaID} />
                    <div className='flex justify-between'>
                        <button onClick={handlePrevStep} className='bg-[#4D4634] text-white px-4 py-2 rounded hover:bg-opacity-80'>{t.back}</button>
                        <button onClick={listener} className='bg-[#4D4634] text-white px-4 py-2 rounded hover:bg-opacity-80'>{t.next}</button>
                    </div>
                </div>);
            case 6:
                return (<div>
                    <label className="block mb-2 font-bold">{t.letter}:</label>
                    <FileDropzone
                        accept={{ 'image/jpeg': [], 'image/png': [] }}
                        onFileAccepted={setLetter} />
                    <div className='flex justify-between'>
                        <button onClick={handlePrevStep} className='bg-[#4D4634] text-white px-4 py-2 rounded hover:bg-opacity-80'>{t.back}</button>
                        <button onClick={listener} className='bg-[#4D4634] text-white px-4 py-2 rounded hover:bg-opacity-80'>{t.next}</button>
                    </div>
                </div>);
            case 7:
                return (<div>
                    <label className="block mb-2 font-bold">{t.photo}:</label>
                    <FileDropzone
                        accept={{ 'image/jpeg': [], 'image/png': [] }}
                        onFileAccepted={setPhoto} />
                    <div className='flex justify-between'>
                        <button onClick={handlePrevStep} className='bg-[#4D4634] text-white px-4 py-2 rounded hover:bg-opacity-80'>{t.back}</button>
                        <button onClick={listener} className='bg-[#4D4634] text-white px-4 py-2 rounded hover:bg-opacity-80'>{t.send}</button>
                    </div>
                </div>);

            default:
                return null;
        }
    };

    const listener = event => {

        if (step === 1) {
            const isValid = stepValidate(step).status;
            if (!isValid) {
                showAlert(stepValidate(step).message);
                return;
            }

            handleNextStep();
        } else if (step === 2) {
            const isValid = stepValidate(step).status;
            if (!isValid) {
                showAlert(stepValidate(step).message);
                return;
            }
            handleFixedEmail();
            handleVerifyEmail();
        } else if (step === 7) {
            const isValid = stepValidate(step).status;
            if (!isValid) {
                showAlert(stepValidate(step).message);
                return;
            }

            handleSubmit();
        } else {
            const isValid = stepValidate(step).status;
            if (!isValid) {
                showAlert(stepValidate(step).message);
                return;
            }

            handleNextStep();
        }
    };

    useEffect(() => {
        const eventEnter = (e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter") {
                e.preventDefault();
                listener();
            }
        }

        document.addEventListener("keydown", eventEnter);
        return () => {
            document.removeEventListener("keydown", eventEnter);
        };
    }, [email, verificationCode, step, t]);

    // automatically close alert after 5 seconds
    useEffect(() => {
        if (message) {
            setTimeout(() => {
                hideAlert();
            }, 5000);
        }
    }, [message]);

    return (
        <div className="useful-contacts container max-w-7xl mx-auto text-gray-900 items-center p-4 min-h-[40vh] flex items-center justify-center">
            <div className='flex flex-col md:flex-row '>
                <div >
                    {message && <div className="bg-red-500 border border-blue-400 text-white px-4 py-3 rounded relative" role="alert" onClick={hideAlert}>{message}</div>}
                    {/* Step of */}
                    <div className="flex items-center justify-between step-indicator mb-10">
                        {step > 1 && <div
                            className="flex items-center  gap-2 cursor-pointer text-[#f29104] font-bold mb-2 hover:text-[#f29104] hover:underline"
                            onClick={() => { setStep(1); setIsVerified(false); setFixedEmail(''); setEmail(''); setVerificationCode(''); }}
                        ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                <path fillRule="evenodd" d="M7.793 2.232a.75.75 0 0 1-.025 1.06L3.622 7.25h10.003a5.375 5.375 0 0 1 0 10.75H10.75a.75.75 0 0 1 0-1.5h2.875a3.875 3.875 0 0 0 0-7.75H3.622l4.146 3.957a.75.75 0 0 1-1.036 1.085l-5.5-5.25a.75.75 0 0 1 0-1.085l5.5-5.25a.75.75 0 0 1 1.06.025Z" clipRule="evenodd" />
                            </svg>
                            {t.changeEmail}
                        </div>}
                        <div className="ml-auto text-[#4D4634] font-bold mb-2">{t.step} {step + 1} {t.of} 8</div>
                    </div>
                    <div className='mx-auto w-fit'>
                        {renderStep()}
                    </div>
                </div>
            </div>

        </div >);
}

export default ApplicationForm;