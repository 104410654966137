import React, { useState, useRef } from 'react';
import { useLanguage } from '../contexts/LanguageContext'
import useAlert from '../utils/Alert';
import parse from 'html-react-parser';
import { validateEmail } from '../utils/emailValidator';
import FileUploadComponent from '../components/FileUploadComponent';
import InputMask from 'react-input-mask';
import Modal from 'react-modal';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

Modal.setAppElement('#root'); // Це потрібно для доступності модального вікна

const code = '1111';

function Complaint() {
    const { message, showAlert, hideAlert } = useAlert();
    const [responseMessage, setResponseMessage] = useState('');
    const { getTranslation } = useLanguage();
    const t = getTranslation('Complaint');
    const [step, setStep] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [docFiles, setDocFiles] = useState([]);
    const [fullName, setFullName] = useState('');
    const [complaint, setComplaint] = useState('');
    const [phone, setPhone] = useState('');

    // Verification
    const [email, setEmail] = useState('');
    const [fixedEmail, setFixedEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [isVerified, setIsVerified] = useState(false);

    const { executeRecaptcha } = useGoogleReCaptcha();

    const fullNameRef = useRef(null);
    const complaintRef = useRef(null);
    const phoneRef = useRef(null);

    const handleNextStep = () => {
        setStep(prevStep => prevStep + 1);
    };

    const handlePrevStep = () => {
        if (step > 2 && step < 4 && isVerified) {
            return
        }

        setStep(prevStep => prevStep - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isLoading) return;

        // Валідація полів
        if (!fullName) {
            showAlert(t.fullNameRequired);
            fullNameRef.current.focus();
            return;
        }

        if (!complaint) {
            showAlert(t.complaintRequired);
            complaintRef.current.focus();
            return;
        }

        if (!executeRecaptcha) {
            showAlert(t.recaptchaError);
            return;
        }

        setIsLoading(true);
        try {

            const formData = new FormData();
            const token = await executeRecaptcha('submit');
            formData.append('fullName', fullName);
            formData.append('phone', phone);
            formData.append('complaint', complaint);
            formData.append('docFiles', docFiles);
            formData.append('g-recaptcha-response', token);
            // Здесь должна быть логика отправки formData на ваш сервер
            const response = await fetch('/actions/formWIthFiles.php', {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const data = await response.json();
            setIsModalOpen(true); // Відкрити модальне вікно

            if (data.status === 'success') {
                // showAlert(t.documentsSubmitted);
                setResponseMessage(t.complaintSubmitted);
            } else {
                // showAlert(data.message);
                setResponseMessage(data.message);
            }
        } catch (error) {
            // showAlert(error.message);
            setResponseMessage(error.message);
        } finally {
            setIsLoading(false);
            setIsModalOpen(true);
            setTimeout(() => {
                setIsModalOpen(false); // Закрити модальне вікно через 2.5 секунди
                setResponseMessage('');
            }, 2500);
        }
    };

    const handleFixedEmail = () => {
        setFixedEmail(email);
    }

    const handleVerifyEmail = () => {
        if (verificationCode !== code) {
            showAlert(t.wrongCode);
            return;
        }

        setIsVerified(true);
        handleNextStep();

    }

    // const handleFileChange = (e) => {
    //     setDocFiles(Array.from(e.target.files));
    // };

    const handleFileChange = (event) => {
        setDocFiles([...docFiles, ...Array.from(event.target.files)]);
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <div className='flex flex-col max-w-[320px] mx-auto'>
                        <p className="block mb-2 font-bold">{t.confirmEmail}</p>
                        <input type="email" name="email" value={email} onChange={e => { setEmail(e.target.value) }} placeholder={t.emailPlaceholder} className='border border-gray-300 p-2 rounded mb-4' />
                        <button onClick={!!email && validateEmail(email) ? e => { handleNextStep(e.target.value); handleFixedEmail() } : ''} disabled={!!!email || !validateEmail(email)} className={`bg-[#4D4634] text-white px-4 py-2 rounded hover:bg-opacity-80 ${!!email && validateEmail(email) ? '' : 'bg-opacity-50 cursor-no-drop'}`}>{t.getCode}</button>
                    </div>
                );
            case 2:
                return (
                    <div className='flex flex-col max-w-[320px] mx-auto'>
                        <p className="block mb-2 font-bold">{t.codeSent} <b>{fixedEmail}</b></p>
                        <input type="text" name="verificationCode" value={verificationCode} onChange={e => setVerificationCode(e.target.value)} placeholder="Код підтвердження" className='border border-gray-300 p-2 rounded mb-4' />
                        <button onClick={handleVerifyEmail} className='bg-[#4D4634] text-white px-4 py-2 rounded hover:bg-opacity-80'>{t.confirm}</button>
                    </div>
                );

            case 3:
                return (<div className='flex flex-col'>
                    <p className="block mb-2 font-bold">{t.uploadDocuments}</p>
                    <label className='w-full'>
                        <p>{t.fullname}</p>
                        <input type="text" name="fullName" placeholder={t.fullname} className='w-full border border-gray-300 p-2 rounded mb-4'
                            value={fullName} onChange={e => setFullName(e.target.value)} ref={fullNameRef}
                        />
                    </label>
                    {/* Phone */}
                    <label className='w-full'>
                        <p>{t.ur_phone}</p>
                        <InputMask mask="+99 (999) 999 99 99" value={phone} onChange={e => setPhone(e.target.value)}>
                            {(inputProps) => <input {...inputProps} type="tel" name="phone" placeholder={t.ur_phone} className='w-full border border-gray-300 p-2 rounded mb-4' ref={phoneRef} />}
                        </InputMask>
                        {/* <input type="tel" name="phone" placeholder={t.ur_phone} className='w-full border border-gray-300 p-2 rounded mb-4' /> */}
                    </label>
                    <label className='w-full'>
                        <p>{t.complaint}</p>
                        <textarea type="text" rows="20" name="complaint" placeholder={t.complaint} className='w-full border border-gray-300 p-2 rounded mb-4'
                            value={complaint} onChange={e => setComplaint(e.target.value)} ref={complaintRef}
                        />
                    </label>

                    <label>{t.addPlaceholder}</label>
                    {/* <input type="file" multiple name="docFiles" onChange={handleFileChange} className='border border-gray-300 p-2 rounded mb-4' /> */}
                    <FileUploadComponent files={docFiles} setFiles={setDocFiles} onFileSelect={handleFileChange} />
                    <button onClick={handleSubmit} type='submit' className='bg-[#4D4634] text-white px-4 py-2 rounded hover:bg-opacity-80 max-w-[320px]'>{t.submit}</button>
                </div>);

            default:
                return null;
        }
    };

    return (
        <div className="useful-contacts container max-w-7xl mx-auto text-gray-900 items-center p-4 min-h-[40vh] flex items-center justify-center">

            <div className='w-full md:w-[60vw]'>
                {message && <div className={"bg-red-500 border border-blue-400 text-white px-4 py-3 rounded relative"} role="alert" onClick={hideAlert}>{message}</div>}
                {/* Step of */}
                <div className="flex items-center justify-between step-indicator mb-10">
                    {step !== 1 && <div
                        className="flex items-center w-1/2 gap-2 cursor-pointer text-[#f29104] font-bold mb-2 hover:text-[#f29104] hover:underline"
                        onClick={() => { setStep(1); setIsVerified(false); setFixedEmail(''); setEmail(''); setVerificationCode(''); }}
                    ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                            <path fillRule="evenodd" d="M7.793 2.232a.75.75 0 0 1-.025 1.06L3.622 7.25h10.003a5.375 5.375 0 0 1 0 10.75H10.75a.75.75 0 0 1 0-1.5h2.875a3.875 3.875 0 0 0 0-7.75H3.622l4.146 3.957a.75.75 0 0 1-1.036 1.085l-5.5-5.25a.75.75 0 0 1 0-1.085l5.5-5.25a.75.75 0 0 1 1.06.025Z" clipRule="evenodd" />
                        </svg>
                        {t.changeEmail}
                    </div>}
                    <div className="place-self-stretch ml-auto text-[#4D4634] font-bold mb-2">{t.step} {step} {t.of} 3</div>
                </div>

                <div>
                    {/* doted step flag */}

                </div>
                {renderStep()}

            </div>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="Form Submission Successful"
                className="modal"
                overlayClassName="modal-overlay"
            >
                <div className="p-4">
                    <button onClick={() => setIsModalOpen(false)} className="text-red-500 rounded hover:bg-opacity-80 absolute top-2 right-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <p>
                        {responseMessage}
                    </p>

                </div>
            </Modal>
        </div >);
}

export default Complaint;