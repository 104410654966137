import { Facebook, Instagram, Telegram, TikTok, Twitter, Youtube, Website } from "../components/SocialTabs";
import { useLanguage } from '../contexts/LanguageContext'

const socialMediaComponents = {
    facebook: Facebook,
    telegram: Telegram,
    instagram: Instagram,
    youtube: Youtube,
    twitter: Twitter,
    tiktok: TikTok,
    website: Website
};


export default function Contacts() {
    const { getTranslation } = useLanguage();
    const t = getTranslation('Contacts');

    return <div className="useful-contacts container max-w-7xl mx-auto text-gray-900 items-center p-4">
        {/* <h1 className="text-3xl md:text-5xl leading-[1.5em] my-12"></h1> */}
        <h1 className="text-3xl md:text-5xl leading-[1.5em] my-12 text-gray-900 font-bold uppercase">{t.title}</h1>

        <div className="et_pb_text_inner">
            {t.contactInfo.map((contact, index) => (
                <div key={index}>
                    <p className="mt-4">{contact.name}:<br />
                        {contact.email && <><a href={`mailto:${contact.email}`}>{contact.email}</a><br /></>}
                        {contact.emails && contact.emails.map(email => <><a href={`mailto:${email}`}>{email}</a><br /></>)}
                        {contact.phone && <a href={`tel:${contact.phone}`}>{contact.phone}</a>}
                        {contact.phones && contact.phones.map(phone => <><a href={`tel:${phone}`}>{phone}</a><br /></>)}
                    </p>
                    {contact.socialLinks && <div className="flex items-center gap-2 mt-2">
                        {Object.keys(contact.socialLinks).map((key) => {
                            const SocialComponent = socialMediaComponents[key];
                            const links = contact.socialLinks[key];

                            if (!SocialComponent) return null;

                            // Если это строка, преобразуем в массив
                            const linksArray = Array.isArray(links) ? links : [links];

                            return linksArray.map((link, linkIndex) => (
                                <SocialComponent key={`${key}-${linkIndex}`} link={link} sx="text-[#4D4634]" />
                            ));
                        })}
                    </div>}
                </div>
            ))}

        </div>
    </div>

}