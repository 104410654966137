import React, { useState, useEffect, useRef } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { useLocation } from 'react-router-dom'; // Импорт из react-router, если вы его используете

const Accordion = ({ id, title, children, open }) => {
    const [isOpen, setIsOpen] = useState(false);
    const accordionRef = useRef(null);

    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const open = params.get('acc'); // 'open' - это параметр из URL
        if (open && open == id) {
            setIsOpen(true);
            if (accordionRef.current) {
                accordionRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location, id]);


    return (
        <div ref={accordionRef} className="border-b border-gray-200" id={`Accordion-${id}`}
        >
            <button
                className="flex text-gray-900 justify-between items-center text-left w-full p-4 focus:outline-none "
                onClick={() => setIsOpen(!isOpen)}
                style={{
                    background: 'linear-gradient(270deg,#F29104 52px,#F4F0E9 0,#F4F0E9 100%)'
                }}
            >
                <span className="font-medium max-w-[89%]">{title}</span>
                {isOpen ? (
                    <ChevronUpIcon className="h-6 w-6 text-white" />
                ) : (
                    <ChevronDownIcon className="h-6 w-6 text-white" />
                )}
            </button>
            <div
                className={`accordionContent relative transition-height duration-500 ease-in-out overflow-hidden clearfix ${isOpen ? 'block h-auto' : 'hidden h-0'
                    }`}
            >
                {/* <div className='accordionWrap'> */}
                {children}
                {/* </div> */}
            </div>
        </div>
    );
};


export const SmallAccordion = ({ id, title, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const open = params.get('sacc'); // 'open' - это параметр из URL

        if (open && open == id) {

            setIsOpen(true);
            // document.getElementById('SmallAccordion-'+id).scrollIntoView(); // Прокрутка к аккордиону
        }
    }, [location, id]);


    return (
        <div id={`SmallAccordion-${id}`} className="accordionInner relative napravle second-title text-gray-900"    >
            <h4 className={`hidingsomething ${isOpen && 'underline'}`} onClick={() => setIsOpen(!isOpen)}
            >{title}</h4>
            <div className={`leading-none pt-2 ${isOpen ? 'h-auto' : 'hidden hiddentext h-0'}`}>
                {children}
            </div>
        </div>
    );
};
export default Accordion;