import { ReactComponent as DesctopSchema } from "../../pages/svg-schema/Scheme_Mediarelations_EN_Desktop_9.svg"
import { ReactComponent as MobileSchema } from "../../pages/svg-schema/Scheme_Mediarelations_EN_Mobile_9.svg"


export default {
    "title": "The interaction algorithm",
    "description": "The Ministry of Defense of Ukraine welcomes the willingness of journalists to report the truth about the war. At the same time, the work of the media in the combat zone is dangerous, so the MOD has a number of rules and restrictions to ensure the safety of both media representatives and the military.<br/>This website has been created to help media representatives cooperate with the Armed Forces of Ukraine, get accreditation and learn the rules of working in combat zones.",
    "poster": 'Media\nRelations',
    DesctopSchema: DesctopSchema,
    MobileSchema: MobileSchema
}