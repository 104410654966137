import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';

function FileUploadComponent({ files, setFiles, onFileSelect }) {
    const { getTranslation } = useLanguage();
    const t = getTranslation('Complaint');

    const handleRemoveFile = (index) => {
        const newFiles = files.filter((_, idx) => idx !== index);
        setFiles(newFiles);
    };

    const handleDownloadFile = (file) => {
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return (
        <div className='w-full mb-4'>
            <input type="file" multiple name="docFiles" onChange={onFileSelect} className='border border-gray-300 p-2 w-full rounded mb-4' />
            {files.length > 0 && (
                <ul>
                    {files.map((file, index) => (
                        <li key={index} className='flex'>
                            {file.name}
                            <button onClick={() => handleRemoveFile(index)} className='ml-auto text-red-400'>{t.remove}</button>
                            <button onClick={() => handleDownloadFile(file)} className='ml-2 text-blue-400'>{t.download}</button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default FileUploadComponent;
