//en

export default {
    // Fill in all required fields
    requiredFields: 'Please fill in all required fields',
    // Documents submitted successfully
    documentsSubmitted: 'Documents submitted successfully',

    docFile: '*Form file (<a href="https://www.mil.gov.ua/content/zmi/Application_Form_2023.docx" target="_new">download</a> sample)',
    idScan: 'ID card scan',
    passportScans: 'Passport scans',
    mediaID: '*Media representative ID scan',
    letter: '*Letter of application scan from media manager',
    photo: '*Passport-sized photo scan',
    submit: 'Submit',
    emailPlaceholder: 'Your E-Mail address',
    codePlaceholder: 'Enter code',
    // Drag and drop files here or click here to select
    dropFiles: 'Drag and drop files here or click here to select',
    or: 'or',

    confirmEmail: 'To submit the application, please confirm your email',
    // Enter code
    enterCode: 'Enter code',
    // Get code
    getCode: 'Get code',
    // Code has been sent to your email
    codeSent: 'Code has been sent to your email',
    // Confirm
    confirm: 'Confirm',
    // Next step
    next: 'Next step',
    // Change email
    changeEmail: 'Change email',
    // Step
    step: 'Step',
    // of
    of: 'of',
    // Wrong confirmation code
    wrongCode: 'Wrong confirmation code',
    // Back
    back: 'Previous step',
    // Send
    send: 'Send',
    // recaptchaError
    recaptchaError: 'reCAPTCHA verification failed. Please try again.',
    // form in bot
    title: 'Submit documents for accreditation in the Armed Forces of Ukraine',
    scanOrId: 'Please upload or scan your ID card or passport scans.',

    description: {
        title: 'To obtain accreditation, you need to submit an application with the following documents:',
        list: [
            'Completed questionnaire <a href="https://www.mil.gov.ua/content/zmi/Application_Form_2023.docx" target="_new">(download sample)</a>;',
            'ID card or the first 3 pages of the passport;',
            "Media representative's identification ( not mandatory for fixers; registered bloggers should provide a copy of the confirmation document issued by the National Council of Ukraine on Television and Radio Broadcasting);",
            'A letter with a request for accreditation signed by your bureau chief or designate;',
            'A high-resolution photo of the person.'
        ],
        footer: 'You need to upload these documents one by one to the corresponding sections of the submission screen.'
    },

    scanOrId: 'Please upload or scan your ID card or passport.',
    emailRequired: 'Please enter your email',
    codeRequired: 'Please enter the code',
    docFileRequired: 'Please upload the form file',
    scanOrIdRequired: 'Please upload the ID card or passport scan',
    letterRequired: 'Please upload the letter of application scan',
    photoRequired: 'Please upload the photo scan',
};