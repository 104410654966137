export default {
    title: "Personal Helper",
    back: "Back to the Beginning",
    content: {
        start: {
            type: 'message',
            title: 'Why should journalists cooperate with the Armed Forces of Ukraine?',
            content: `Working for the media in the combat zone is dangerous, so there are several restrictions imposed for the safety of journalists and military personnel.<br/>
                       On this website, you can find information about accreditation, familiarize yourself with the rules of media work in the frontline areas, and learn about the interaction with the Armed Forces of Ukraine.`,
            trigger: 'options-1',
        },
        'options-1': {
            type: 'options',
            title: 'Select a Topic',
            options: [
                { value: 'accreditation', label: 'Accreditation of the Armed Forces of Ukraine', trigger: 'accreditation' },
                { value: 'frontline-work', label: 'Working near the Frontline', trigger: 'frontline-work' },
                { value: 'contacts', label: 'Contacts for Journalists', trigger: 'contacts' },
                { value: 'submit-complaint', label: 'Submit a Complaint', trigger: 'submit-complaint' },
            ],
        },
        "accreditation": {
            "type": "message",
            "title": "Accreditation in the Armed Forces of Ukraine",
            "trigger": "options-accreditation",
            "content": "<p>Accreditation in the Armed Forces of Ukraine gives media personnel the right to perform editorial tasks in combat zones and at military facilities in the rear. The presence of accreditation is confirmed by a personalized document of the established sample.</p>"
        },
        "options-accreditation": {
            type: "options",
            title: "Select a Topic",
            options: [
                { "value": "rules", "label": "Rules", "trigger": "rules" },
                { "value": "documents-list", "label": "Required Documents", "trigger": "documents-list" },
                { "value": "submit-documents", "label": "Submit Documents", "trigger": "submit-documents" },
                { "value": "check-status", "label": "Check Request Status", "trigger": "check-status" },
                { "value": "problem", "label": "Encountering an Issue", "trigger": "problem" },
                { "value": "back", "label": "Go Back", "trigger": "options-1" }
            ]
        },
        //rules
        "rules": {
            "type": "message",
            "title": "Rules of Accreditation in the Armed Forces of Ukraine",
            "trigger": "rules-options-1",
            "content": "- Representatives of media and bloggers registered in accordance with the Law of Ukraine 'On Media' can apply for accreditation.<br/> - The submitted information is verified by the state authorities of Ukraine. A press card is issued or a refusal is notified based on the results.<br/> - The verification process takes 10-15 days but may be extended if necessary.<br/> - Accreditation may be terminated for transferring the press card to another person, refusal to comply with or violation of the <a href='/en/rules' target='_new'>Rules</a> of work in the combat zone."
        },
        //rules-options-1
        "rules-options-1": {
            "type": "options",
            "title": "Select a Topic",
            "options": [
                { "value": "documents-list", "label": "List of Required Documents", "trigger": "documents-list" },
                { "value": "submit-documents", "label": "Submit Documents", "trigger": "submit-documents" },
                { "value": "back", "label": "Go Back", "trigger": "options-accreditation" }
            ]
        },
        //documents-list
        "documents-list": {
            "type": "message",
            "title": "List of Documents Required for Accreditation in the Armed Forces of Ukraine",
            "trigger": "documents-list-options-1",
            "content": "<ul><li>• Completed <a href='https://www.mil.gov.ua/content/zmi/acreditation_2024.docx' target='_blank'>form</a>;</li><li>• Scan of ID card or the first two pages of the passport;</li><li>• Scan of media representative identification (<sup>*</sup>not required for fixers, <sup>**</sup>for registered bloggers – scan of the confirming document from the National Council on Television and Radio Broadcasting);</li><li>• Scan of a letter of request from the media manager;</li><li>• Scan of a high-resolution photo.</li></ul>"
        },
        "documents-list-options-1": {
            "type": "options",
            "title": "Select a Topic",
            "options": [
                { "value": "submit-documents", "label": "Submit Documents", "trigger": "submit-documents" },
                { "value": "back", "label": "Go Back", "trigger": "options-accreditation" }
            ]
        },
        "submit-documents-options-1": {
            "type": "options",
            "title": "Select a Topic",
            "options": [
                { "value": "frontline-work", "label": "Frontline Work", "trigger": "frontline-work" },
                { "value": "contacts", "label": "Journalist Contacts", "trigger": "contacts" },
                { "value": "back", "label": "Go Back", "trigger": "options-accreditation" }
            ]
        },
        "check-status": {
            "type": "message",
            "title": "Check Application Status",
            "trigger": "check-status-options-1",
            "content": "<p>Contact <a href='mailto:newsmodu@gmail.com'>newsmodu@gmail.com</a> or call <a href='tel:+380894201864'>+380-89-420-18-64</a>.</p>"
        },
        "check-status-options-1": {
            "type": "options",
            "title": "Select a Topic",
            "options": [
                { "value": "frontline-work", "label": "Frontline Work", "trigger": "frontline-work" },
                { "value": "contacts", "label": "Journalist Contacts", "trigger": "contacts" },
                { "value": "back", "label": "Go Back", "trigger": "options-accreditation" }
            ]
        },
        "problem": {
            "type": "message",
            "title": "Facing Issues with AFU Accreditation?",
            "trigger": "problem-options-1",
            "content": "<p>Check the <a href='/en/rules' target='_new'>rules</a><br/><br/><a href='/en/faq' target='_new'>Frequently Asked Questions</a></p>"
        },
        "problem-options-1": {
            "type": "options",
            "title": "Select a Topic",
            "options": [
                { "value": "check-status", "label": "No Response to Application", "trigger": "check-status" },
                { "value": "refusal-reason", "label": "Received Accreditation Refusal", "trigger": "refusal-reason" },
                { "value": "lost-press-card", "label": "Lost Press Card", "trigger": "lost-press-card" },
                { "value": "back", "label": "Go Back", "trigger": "options-accreditation" }
            ]
        },
        "refusal-reason": {
            "type": "message",
            "title": "If Accreditation is Refused",
            "trigger": "options-refusal-reason",
            "content": "<p>If accreditation by AFU is refused, you should be informed along with the reasoning behind such a decision. If you were not informed of the reason for refusal, contact <a href='mailto:newsmodu@gmail.com'>newsmodu@gmail.com</a> or call <a href='tel:+380894201864'>+380-89-420-18-64</a>. To appeal the refusal, send an official letter from the media's manager (editor) to <a href='mailto:newsmodu@gmail.com'>newsmodu@gmail.com</a>.</p>"
        },
        "options-refusal-reason": {
            "type": "options",
            "title": "Select a Topic",
            "options": [
                { "value": "back", "label": "Go Back", "trigger": "options-accreditation" }
            ]
        },
        "lost-press-card": {
            "type": "message",
            "title": "If Press Card is Lost",
            "trigger": "options-lost-press-card",
            "content": "<p>If you have lost your press card, immediately contact <a href='tel:+380894201864'>+380-89-420-18-64</a> or email <a href='mailto:newsmodu@gmail.com'>newsmodu@gmail.com</a> to receive instructions for further actions.</p>"
        },
        "options-lost-press-card": {
            "type": "options",
            "title": "Select a Topic",
            "options": [
                { "value": "back", "label": "Go Back", "trigger": "options-accreditation" }
            ]
        },
        //frontline-work
        "frontline-work": {
            "type": "message",
            "title": "Working in the Combat Zone",
            "content": "",
            "trigger": "frontline-work-options"
        },
        "frontline-work-options": {
            "type": "options",
            "title": "Select a Topic",
            "options": [
                { "value": "have-accreditation", "label": "I Have AFU Accreditation", "trigger": "have-accreditation" },
                { "value": "no-accreditation", "label": "I Don't Have AFU Accreditation", "trigger": "no-accreditation" },
                { "value": "obligations", "label": "Media Representatives' Obligations", "trigger": "obligations" },
                { "value": "questions-about-work", "label": "Questions about Media Work in the Combat Zone", "trigger": "questions-about-work" },
                { "value": "back", "label": "Go Back", "trigger": "options-1" }
            ]
        },
        "have-accreditation": {
            "type": "message",
            "title": "I Have AFU Accreditation",
            "content": "<p>AFU needs to ensure that the operational situation in the combat zone allows media to work in your intended area. To do this, you need to obtain approval from the Public Affairs Office of the respective Joint Task Force (JTF).</p>",
            "trigger": "where-to-work"
        },
        "where-to-work": {
            "type": "message",
            "title": "Where do you plan to work?",
            "content": "",
            "trigger": "region-selection"
        },
        "region-selection": {
            "type": "options",
            "title": "Select the Region",
            "options": [
                {
                    "value": "donetsk",
                    "label": "Donetsk Region (→ JTF “Khortytsia”)",
                    "trigger": "region-info",
                    "metadata": {
                        "region": "Donetsk Region",
                        "osuv": "JTF “Khortytsia”",
                        "emails": ["presscentrjfo@post.mil.gov.ua"]
                    }
                },
                {
                    "value": "zaporizhzhia",
                    "label": "Zaporizhzhia Region (→ JTF “Tavria”)",
                    "trigger": "region-info",
                    "metadata": {
                        "region": "Zaporizhzhia Region",
                        "osuv": "JTF “Tavria”",
                        "emails": ["tavriyapress@post.mil.gov.ua"]
                    }
                },
                {
                    "value": "luhansk",
                    "label": "Luhansk Region (→ JTF “Khortytsia”)",
                    "trigger": "region-info",
                    "metadata": {
                        "region": "Luhansk Region",
                        "osuv": "JTF “Khortytsia”",
                        "emails": ["presscentrjfo@post.mil.gov.ua"]
                    }
                },
                {
                    "value": "sumy",
                    "label": "Sumy Region (→ JTF “Pivnich”)",
                    "trigger": "region-info",
                    "metadata": {
                        "region": "Sumy Region",
                        "osuv": "JTF “Pivnich”",
                        "emails": ["pressa.koszsu@post.mil.gov.ua"]
                    }
                },
                {
                    "value": "kharkiv",
                    "label": "Kharkiv Region (→ JTF “Khortytsia”)",
                    "trigger": "region-info",
                    "metadata": {
                        "region": "Kharkiv Region",
                        "osuv": "JTF “Khortytsia”",
                        "emails": ["presscentrjfo@post.mil.gov.ua"]
                    }
                },
                {
                    "value": "kherson",
                    "label": "Kherson Region (→ JTF “Tavria”)",
                    "trigger": "region-info",
                    "metadata": {
                        "region": "Kherson Region",
                        "osuv": "JTF “Tavria”",
                        "emails": ["tavriyapress@post.mil.gov.ua"]
                    }
                },
                {
                    "value": "back",
                    "label": "Go Back",
                    "trigger": "frontline-work-options"
                }
            ]
        },
        "region-options": {
            "type": "options",
            "title": "Select the Region",
            "options": [
                {
                    "value": "back",
                    "label": "Go Back",
                    "trigger": "region-selection"
                }
            ]
        },
        "no-accreditation": {
            "type": "message",
            "title": "You Need to Obtain AFU Accreditation",
            "trigger": "accreditation-options",
            "content": "<p>For work in the combat zone, media personnel must obtain AFU accreditation and approval from the Public Affairs Office of the JTF. This rule applies even if AFU personnel have invited you to their positions and guaranteed safety.</p>"
        },
        "accreditation-options": {
            "type": "options",
            "title": "Select a Topic",
            "options": [
                { "value": "afu-accreditation", "label": "AFU Accreditation", "trigger": "accreditation" },
                { "value": "back", "label": "Go Back", "trigger": "frontline-work" }
            ]
        },
        // obligations
        "obligations": {
            "type": "message",
            "title": "Media Personnel Obligations in the Combat Zone",
            "trigger": "obligations-options",
            "content": "<ul><li>• Present personal documents, accreditation, and approval from the relevant JTF;</li><li>• Move according to the agreed plan;</li><li>• Wear body armour, helmet, and first aid kit;</li><li>• Display media representative identification signs on vehicles;</li><li>• Provide data carriers for verification of non-disclosable information and delete such information;</li><li>• Disclose <a href='/en/rules' target='_new'>classified and sensitive information;</a></li><li>• Not wear military uniforms or insignia, not handle weapons and ammunition;</li></ul><br/> <a href='/en/rules' target='_new'>Details on media obligations</a>."
        },
        "obligations-options": {
            "type": "options",
            "title": "Select a Topic",
            "options": [
                { "value": "obligations-accreditations", "label": "Obtain Accreditation", "trigger": "accreditation" },
                { "value": "obligations-region-selection", "label": "Get JTF Approval", "trigger": "region-selection" }
            ]
        },

        // Є питання щодо роботи медіа в зоні бойових дій?
        "questions-about-work": {
            "type": "options",
            "title": "Do You Have Questions About Media Work in the Combat Zone?",
            "options": [
                { "value": "working-zone", "label": "Work Zones", "trigger": "working-zone" },
                { "value": "responsibility-afu", "label": "Responsibility of the AFU", "trigger": "responsibility-afu" },
                { "value": "media-restrictions", "label": "Media Work Restrictions", "trigger": "media-restrictions" },
                { "value": "confidential-information", "label": "Responsibility for Disclosure of Confidential Information", "trigger": "confidential-information" },
                // back to previous step
                { "value": "back", "label": "Go Back", "trigger": "frontline-work" }
            ]
        },
        "working-zone": {
            "type": "message",
            "title": "Work Zones",
            "content": "<p>Joint Task Forces (JTF) of the AFU divide the areas of their responsibility into zones:</p><ul><li>• In the green zone, media can work without the accompaniment of an authorized representative of the AFU.</li><li>• In the yellow zone, media can work without accompaniment in public places open to civilians.</li><li>• In the red zone, media work is allowed in exceptional cases and only accompanied by an authorized representative of the AFU.</li></ul>",
            "trigger": "questions-about-work"
        },
        "responsibility-afu": {
            "type": "message",
            "title": "AFU Responsibility",
            "content": "<p>The AFU make every effort to preserve the lives and health of media representatives, but they do not bear direct responsibility for this. Therefore, journalists are required to use personal protective equipment and have a first aid kit with them.</p>",
            "trigger": "questions-about-work"
        },
        "media-restrictions": {
            "type": "message",
            "title": "Media Restrictions",
            "content": "<p>To maintain operational security, AFU officials may restrict photo and video shooting in certain cases. Operational security units also have the right to preview captured photo and video materials and demand their deletion from data carriers.</p>",
            "trigger": "questions-about-work"
        },
        "confidential-information": {
            "type": "message",
            "title": "Responsibility for Disclosure of Confidential Information",
            "content": "<p>Article 114-2 of the Criminal Code of Ukraine provides for imprisonment for a term of 3 to 5 years for unauthorized dissemination of information about the direction, movement of weapons, armament, and ammunition into Ukraine, the movement, displacement, or deployment of the AFU or other military formations established in accordance with the laws of Ukraine, committed under conditions of war or a state of emergency.</p>",
            "trigger": "questions-about-work"
        },
        "contacts": {
            "type": "message",
            "title": "Contacts",
            "trigger": "contacts-options",
            "content": ""
        },
        "contacts-options": {
            "type": "options",
            "title": "Select Topic",
            "options": [
                { "value": "mod-contacts", "label": "Ministry of Defence Press and Information Management", "trigger": "mod-contacts" },
                { "value": "general-staff-contacts", "label": "Main Communications Directorate of the Armed Forces of Ukraine", "trigger": "general-staff-contacts" },
                { "value": "branches-contacts", "label": "Command of Branches, Types of Troops, and Forces of the Armed Forces of Ukraine", "trigger": "branches-contacts" },
                { "value": "osuv-contacts", "label": "Joint Task Forces of Ukrainian Forces", "trigger": "osuv-contacts" },
                { "value": "minister-interview-requests", "label": "Interviews with the Ukrainian Minister of Defence and Deputies", "trigger": "minister-interview-requests" },
                { "value": "supremecommander-interview-requests", "label": "Interviews with the Commander-in-Chief of the Armed Forces of Ukraine and Deputies, Chief of the General Staff, Deputies, Commanders of Joint Task Forces, Branches, Types of Troops, and Forces of the Armed Forces of Ukraine", "trigger": "supremecommander-interview-requests" },
                { "value": "filming-requests", "label": "Filming of Combat Equipment Received from Foreign Partners", "trigger": "filming-requests" },
                { "value": "back", "label": "Back", "trigger": "options-1" }
            ]
        },
        "mod-contacts": {
            "type": "message",
            "title": "Ministry of Defence Press and Information Management",
            "content": "<p><a href=\"mailto:psmodu@post.mil.gov.ua\">psmodu@post.mil.gov.ua</a><br><a href=\"tel:+380963109450\">+380-96-310-9450</a></p>",
            "trigger": "contacts-options"
        },
        "general-staff-contacts": {
            "type": "message",
            "title": "Armed Forces of Ukraine Public Affairs Office (General Staff Press Service)",
            "content": "<p><a href=\"mailto:press@post.mil.gov.ua\">press@post.mil.gov.ua</a><br><a href=\"tel:+380444544255\">+380-44-454-4255</a></p>",
            "trigger": "contacts-options"
        },
        "branches-contacts": {
            type: "message",
            title: "Branch Commands of the Armed Forces of Ukraine",
            content: `<p>Ground Forces<br>  
            <a href="mailto:press.ksv@mil.gov.ua">press.ksv@mil.gov.ua</a>
            </p><br>
            <p>Air Forces<br><a href="mailto:uafpc@ps.mil.gov.ua">uafpc@ps.mil.gov.ua</a></p>
            <br><p>Navy<br>  <a href="mailto:admindep@post.mil.gov.ua">admindep@post.mil.gov.ua</a></p>
            <br><p>Airborne Forces<br>  <a href="mailto:pressa_dshv@post.mil.gov.ua">pressa_dshv@post.mil.gov.ua</a></p><br>
            <p>Special Operations Forces<br>  <a href="mailto:press_softcom@post.mil.gov.ua">press_softcom@post.mil.gov.ua</a></p>
            <br><p>Medical Forces<br>  <a href="mailto:kms_zsu@post.mil.gov.ua">kms_zsu@post.mil.gov.ua</a></p><br>
            <p>Support Forces<br>  <a href="mailto:ksppress@post.mil.gov.ua">ksppress@post.mil.gov.ua</a></p><br>
            <p>Logistics Forces<br>  <a href="mailto:prs-ksl@post.mil.gov.ua">prs-ksl@post.mil.gov.ua</a></p>
            <br><p>Communications and Cybersecurity Forces:<br>  <a href="mailto:ccs_command_afua@post.mil.gov.ua">ccs_command_afua@post.mil.gov.ua</a></p>
            <br>
            <p>Territorial Defense Command of the of the Armed Forces of Ukraine:<br>  
                <a href="mailto:tro.info@post.mil.gov.ua">tro.info@post.mil.gov.ua</a>
                <a href="mailto:to_info@post.mil.gov.ua">to_info@post.mil.gov.ua</a>
            </p>
            <br>
            <p>The Main Directorate of the Military Police Service of the Armed Forces of Ukraine:<br>  
                <a href="mailto:och_vsp@post.mil.gov.ua">och_vsp@post.mil.gov.ua</a>
                <a href="mailto:pressvsp@gmail.com">pressvsp@gmail.com</a>
            </p>
            <br>
            <p>The Joint Forces Command of the Armed Forces of Ukraine:<br>  
                <a href="mailto:pressa.koszsu@post.mil.gov.ua">pressa.koszsu@post.mil.gov.ua</a>
            </p>
            `,
            trigger: "contacts-options"
        },
        "osuv-contacts": {
            "type": "message",
            "title": "Joint Task Forces of the Armed Forces of Ukraine",
            "content": `<p><strong>Siversk operational and tactical group</strong><br>  <a href=\"mailto:presspivnich@post.mil.gov.ua\">presspivnich@post.mil.gov.ua</a></p>
            <br>
            <p><strong>Joint Task Force 'Tavria'</strong><br>  <a href=\"mailto:tavriyapress@post.mil.gov.ua\">tavriyapress@post.mil.gov.ua</a></p>
            <br>
            <p><strong>Joint Task Force 'Khortytsia'</strong><br>  <a href=\"mailto:presscentrjfo@post.mil.gov.ua\">presscentrjfo@post.mil.gov.ua</a></p>`,
            "trigger": "contacts-options"
        },
        "filming-requests": {
            "type": "message",
            "title": "Filming of Combat Equipment Received from Foreign Partners",
            "content": "<p><a href=\"mailto:press@post.mil.gov.ua\">press@post.mil.gov.ua</a><br><a href=\"tel:+380444544255\">+380-44-454-4255</a></p>",
            "trigger": "contacts-options"
        },
        "minister-interview-requests": {
            "type": "message",
            "title": "Interviews with the Minister of Defence of Ukraine and Deputies",
            "content": "<p><a href=\"mailto:psmodu@post.mil.gov.ua\">psmodu@post.mil.gov.ua</a><br><a href=\"tel:+380963109450\">+380-96-310-9450</a></p>",
            "trigger": "contacts-options"
        },
        "supremecommander-interview-requests": {
            "type": "message",
            "title": "Interviews with the Commander-in-Chief of the Armed Forces of Ukraine and Deputies, Chief of the General Staff, Deputies, Commanders of Joint Task Forces, Branches, and Types of Forces and Units of the Armed Forces of Ukraine",
            "content": "<p><a href=\"mailto:press@post.mil.gov.ua\">press@post.mil.gov.ua</a><br><a href=\"tel:+380444544255\">+380-44-454-4255</a></p>",
            "trigger": "contacts-options"
        }
    }
}
