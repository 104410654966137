
export default {
    mediaDutiesTitle: "ОБОВ'ЯЗКИ ПРЕДСТАВНИКІВ МЕДІА",
    mediaDuties: [
        "мати при собі та пред’являти прескарту і документи, що посвідчують особу;",
        "у районі ведення бойових дій переміщуватися виключно у терміни та за маршрутом, погодженими із ЗСУ;",
        "мати при собі засоби індивідуального захисту: захисний шолом, бронежилет з маркуванням ПРЕСА / PRESS;",
        "мати при собі медичну аптечку та вміти нею користуватись;",
        "носити на собі та розміщувати на транспорті знаки розпізнавання представників медіа, за винятком ситуацій, коли це може загрожувати життю та здоров’ю;",
        "не вдягати формений одяг та знаки розрізнення Збройних Сил, інших складових сил оборони, не брати до рук зброю та боєприпаси;",
        "за вимогою передавати уповноваженій особі носії з фото- та відеоматеріалами для перевірки на предмет наявності інформації, що не підлягає розголошенню; у разі наявності видалити таку інформацію з носія."

    ],
    mediaProhibitedTitle: "Не підлягає розголошенню інформація, яка може стати відомою представнику медіа під час роботи в районах ведення бойових дій і визначена:",
    mediaProhibited: [
        "в статті 17 <a href='https://zakon.rada.gov.ua/laws/show/638-15#Text' target='_new'>Закону України «Про боротьбу з тероризмом»</a>;",
        "<a href='https://zakon.rada.gov.ua/laws/show/z0052-21#Text' target='_new'>наказом Служби безпеки України від 23 грудня 2020 року №383</a>;",
        "<a href='https://zakon.rada.gov.ua/rada/show/v0605322-23#Text' target='_new'>наказом Міністерства оборони України від 17 жовтня 2023 року №605</a>;",
        "наказом Генерального штабу Збройних Сил України від 30 січня 2023 №12;",
        "переліком інформації, розголошення якої може призвести до обізнаності противника про дії Збройних Сил України та інших складових сил оборони:",
            "найменування військових частин і підрозділів Сил оборони України; координати місць розташування; чисельність особового складу; кількість, місця зберігання, технічний стан озброєння та бойової техніки;",
            "tописи, зображення та умовні позначки, які ідентифікують військові об’єкти;",
            "щодо військових операцій, які проводяться чи плануються;",
            "щодо системи охорони та оборони;",
            "порядок залучення сил та засобів до виконання бойових завдань;",
            "про збір розвідувальних даних: способи, методи, сили та засоби, що залучаються",
            "про переміщення та розгортання українського війська: найменування, кількість, місця, райони, маршрути руху",
            "про проведення унікальних операцій із зазначенням прийомів та способів, що використовувались;",
            "про ефективність сил і засобів радіоелектронної боротьби противника;",
            "про відкладені, на етапі підготовки чи скасовані операції українських військових;",
            "про зниклий або збитий літак, літальний апарат, зникле судно та пошуково-рятувальні операції, які плануються або проводяться;",
            "про планування та проведення заходів гарантування безпеки застосування військ: дезінформація, імітація, демонстративні дії, маскування, протидія технічним розвідкам та захист інформації;",
            "про втрати бойової техніки та особового складу, якщо така інформація не розміщувалась у відкритому доступі Генеральним штабом ЗС України;",
            "фото- та відеоматеріали із зображенням знаків взаємного розпізнавання, бортовими номерами та державними номерними знаками на бойовій техніці, засобів цілевказівок, бойового управління та розвідки, конструктивних особливостей на бойовій техніці;",
            "фото та відеоматеріали з середини танків, самохідних артилерійських установок, зенітно-ракетних комплексів, бойових броньованих машин та літаків тактичної авіації;",
            "інформація про терміни поставок, типи, кількість, матеріально-технічне забезпечення, місця зберігання озброєння та військової техніки, які надаються у рамках допомоги від країн-партнерів, якщо така інформація не розміщувалася на офіційних сторінках Міністерства оборони України та Генерального штабу ЗС України або відомствами країн-партнерів;",
            "про проведені інформаційні та психологічні операції;",
        "Категорично заборонено транслювати в прямому ефірі роботу протиповітряної оборони, публікувати точні адреси влучання ракет, використовувати автомобільні відеореєстратори та публікувати зроблені відеореєстраторами фото і відеозаписи."
    ]
}