import React, { useEffect } from 'react'
import Accordion, { SmallAccordion } from '../components/Accordion'
import { useLanguage } from '../contexts/LanguageContext';

function FAQ({ content, indexedData, accordionData }) {
    const [search, setSearch] = React.useState('')
    const [filteredData, setFilteredData] = React.useState(accordionData);
    console.log(content)
    const handleSearch = (e) => {
        const query = e.target.value;
        setSearch(query);

        // Если строка поиска пустая, отображаем все данные
        if (!query) {
            setFilteredData(accordionData);
            return;
        }

        // Получаем результаты поиска
        const searchResults = searchInData(query, indexedData);


        const filteredResults = accordionData
            .map(group => {
                // Фильтруем вопросы внутри группы на основе результатов поиска
                const filteredQuestions = group.questions.filter(question =>
                    searchResults.some(result => result.id === question.id)
                );

                // Если в группе есть отфильтрованные вопросы, возвращаем группу с этими вопросами
                if (filteredQuestions.length > 0) {
                    return { ...group, questions: filteredQuestions };
                }
                return null;
            })
            .filter(Boolean); // Удаляем null значения

        setFilteredData(filteredResults);

    };

    const searchInData = (query, dataArray) => {
        let results = [];
        const queryWords = query.toLowerCase().split(' ');
        const queryPhrase = query.toLowerCase();

        dataArray.forEach(item => {
            let relevance = 0;
            const title = item.title?.toLowerCase();
            const content = item.content?.toLowerCase();

            // Проверка на полное совпадение фразы
            if (title.includes(queryPhrase) || content.includes(queryPhrase)) {
                relevance += 5; // Большой вес для полного совпадения фразы
            }

            // Проверка на совпадение всех слов запроса
            let allWordsMatch = queryWords.every(word => title.includes(word) || content.includes(word));
            if (allWordsMatch) {
                relevance += 2; // Дополнительный вес, если все слова совпадают
            }

            if (relevance > 0) {
                results.push({ ...item, relevance });
            }
        });

        return results.sort((a, b) => b.relevance - a.relevance);
    };

    useEffect(() => {
        handleSearch({ target: { value: search } });
    }
        , [indexedData, search, accordionData]);

    return <div className="container max-w-7xl mx-auto text-white items-center p-4">
        <h1 className="text-3xl md:text-5xl leading-[1.5em] my-12 text-gray-900 font-bold uppercase">{content.title}</h1>

        <form>
            <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">{content.search}</label>
            <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                </div>
                <input onChange={
                    handleSearch
                } type="search" id="default-search" className="outline-0 block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder={content.search} />
            </div>
        </form>

        <div className="w-full mx-auto mt-10 space-y-4">
            {filteredData.map((group, i) => {
                return <div key={i}>
                    <h2 className="text-2xl font-bold">{group.title}</h2>
                    {group.questions.map((item, index) => {
                        return (<div key={index} className='mb-4'>
                            <Accordion id={item.id} title={item.title}>
                                {item.content && <div className='text-gray-900' style={{
                                    margin: '0 0 0 15px',
                                    padding: '25px 0 0 6px'
                                }} dangerouslySetInnerHTML={{ __html: item.content }} />}

                                {item?.smallAccordion && item.smallAccordion.map((item, index) => <SmallAccordion key={index} id={item.id} title={item.smallTitle}>
                                    <div dangerouslySetInnerHTML={{ __html: item.content }} />
                                </SmallAccordion>)}
                            </Accordion></div>)
                    }
                    )}
                </div>
            })
            }

        </div>

    </div>
}

const indexData = (data) => {
    let index = {};
    data.forEach(group => {
        group.questions.forEach(item => {
            index[item.id] = { ...item, weight: 1 };  // Вес для title
        })
        // item.smallAccordion?.forEach(smallItem => {
        //     index[smallItem.id] = { ...smallItem, weight: 0.5 };  // Меньший вес для smallAccordion
        // });
    });
    return index;
};


const FAQWrap = () => {
    const { getTranslation, language } = useLanguage();
    const t = getTranslation('FAQ');
    const [accordionData, setAccordionData] = React.useState(t.questions);
    const [indexedData, setIndexedData] = React.useState(indexData(accordionData));


    useEffect(() => {
        console.log(t.questions)
        setAccordionData(t.questions);
        // Перемістіть виклик setIndexedData внутрішньо до callback setState, щоб він виконувався після оновлення accordionData
        setIndexedData(indexData(t.questions));
    }, [language, t.questions]); // Додайте t.questions як залежність, якщо вона може змінюватися з мовою

    // const accordionData = t.questions
    // const indexedData = indexData(accordionData);

    return <FAQ content={t} indexedData={Object.values(indexedData)} accordionData={accordionData} />
}

export default FAQWrap
