import { random } from "../../utils/random";

export default {
    "title": "Frequently asked questions",
    "search": "Search",
    "questions": [
        {
            title: 'Accreditation',
            questions: [
                {
                    id: random(1, 99999),
                    title: "What is accreditation in the Armed Forces of Ukraine (AFU)?",
                    content: "Accreditation from the Armed Forces of Ukraine (AFU) grants media personnel the right to perform editorial tasks and engage in journalistic activities in areas of hostilities and at military facilities in the rear."
                },
                {
                    id: random(1, 99999),
                    title: "Why does the AFU accredit media?",
                    content: "By accrediting journalists, the Armed Forces of Ukraine create conditions for the dissemination of timely and objective information about the Russo-Ukrainian war. In doing so, the AFU take measures necessary to ensure the safety of military operations, and to preserve the lives and health of journalists and military personnel."
                },
                {
                    id: random(1, 99999),
                    title: "Who can apply for accreditation?",
                    content: "An application can be submitted by a representative of the media or a blogger registered as a subject in the field of online media as provided for by the Law of Ukraine 'On Media'."
                },
                {
                    id: random(1, 99999),
                    title: "What is the duration of accreditation?",
                    content: "Accreditation is granted for a period of up to 12 months."
                },
                {
                    id: random(1, 99999),
                    title: "What happens after submitting an accreditation request?",
                    content: "The journalist's data is verified by relevant state institutions of Ukraine. Based on the verification results, the media personnel is issued a press card, or notified of the refusal of accreditation, along with the justification for such a decision."
                },
                {
                    id: random(1, 99999),
                    title: "How long does it take to receive a decision on accreditation?",
                    content: "With the necessary accreditation documents available, a response may be provided within approximately 10-15 days. If additional verification by relevant state authorities is required, the term may be extended."
                },
                {
                    id: random(1, 99999),
                    title: "How can the accreditation status be checked?",
                    content: "To check the accreditation status, contact <a href='mailto:newsmodu@gmail.com'>newsmodu@gmail.com</a> or call <a href='tel:+380894201864'>+380-89-420-18-64</a>."
                },
                {
                    id: random(1, 99999),
                    title: "Under what conditions can a media representative be deprived of accreditation?",
                    content: "Grounds for terminating accreditation may include refusal to adhere to <a href='/en/rules'>Rules</a> for media representatives' work in areas of hostilities or their violation, as well as transferring the press card to another person."
                },
                {
                    id: random(1, 99999),
                    title: "What is a press card?",
                    content: "It is a personalised document of a specified sample that confirms the media representative's accreditation from the AFU. It is sent in electronic format to the media representative's email by the Public Relations Department of the Armed Forces of Ukraine after they have completed the accreditation procedure."
                },
                {
                    id: random(1, 99999),
                    title: "How to obtain a press card?",
                    content: "Fill out the form at the following <a href='https://www.mil.gov.ua/content/zmi/acreditation_2024.docx'>link</a> and send it to the email address <a href='mailto:newsmodu@gmail.com'>newsmodu@gmail.com</a> along with copies of the following documents:\n<ul> <li>• ID card or the first 3 pages of the passport; <li>• Media representative's identification (not mandatory for fixers / registered bloggers provide a copy of the confirming document issued by the National Council of Ukraine on Television and Radio Broadcasting); <li>• A request for accreditation; <li>• A high-resolution photo of the person.</ul>"
                },
                {
                    id: random(1, 99999),
                    title: "What to do in case of losing the press card?",
                    content: "Immediately contact by phone at <a href='tel:+380894201864'>+380-89-420-18-64</a> or email at <a href='mailto:newsmodu@gmail.com'>newsmodu@gmail.com</a> to receive instructions for further actions."
                }
            ]
        },


        {
            title: 'Request for Work in Combat Zones',
            questions: [
                {
                    id: random(1, 99999),
                    title: "Where to submit a request for work in combat zones",
                    content: "A request for work in a combat zone should be sent to the public relations body of the corresponding military formation (see <a href='/en/contacts'>Contacts</a> section)."
                },
                {
                    id: random(1, 99999),
                    title: "Why submit a request if accreditation already exists?",
                    content: "The Ukrainian Armed Forces need to ensure whether the operational situation in this area allows the work of media representatives."
                },
                {
                    id: random(1, 99999),
                    title: "Ukrainian Armed Forces personnel invited media representatives to their positions and guaranteed safety. Is accreditation and a request for work in combat zones required?",
                    content: "The work of media representatives in combat zones is possible only with accreditation from the Ukrainian Armed Forces and the approval of the public relations unit of the corresponding Joint Task Force (JTF)."
                },
                {
                    id: random(1, 99999),
                    title: "What is the deadline for submitting a request for work in combat zones?",
                    content: "The request should be sent no later than three working days before the planned visit date."
                }
            ]
        },
        {
            title: 'Work in Combat Zones',
            questions: [
                {
                    id: random(1, 99999),
                    title: "In which areas of combat zones can media operate?",
                    content: "The commands of Joint Task Force (JTF) formations of the Armed Forces of Ukraine divide areas of their responsibility into <strong>green, yellow, and red zones</strong>.\n <ul> <li>• In the green zone, media can operate without the accompaniment of a press officer or other military personnel. <li>• In the yellow zone, media can operate without accompaniment only in public places accessible to civilians. <li>• Media work in the red zone may be permitted as an exception, upon the request of the Ministry of Defence of Ukraine or the General Staff of the Armed Forces of Ukraine.</ul>"
                },
                {
                    id: random(1, 99999),
                    title: "How to find out which zone belongs to the settlement where I plan to work?",
                    content: "Contact the Department of Public Relations of the Armed Forces of Ukraine or the press center of the operational-strategic grouping of the Armed Forces of Ukraine («Contacts» section)."
                },
                {
                    id: random(1, 99999),
                    title: "Servicemen of the Ukrainian Armed Forces invited media representatives to their position. Do they need accreditation and a request for work in the combat zone?",
                    content: "The work of media representatives in the combat zone is possible only if there is accreditation from the Armed Forces and the approval of the public relations unit of the relevant operational-strategic (operational-tactical) group of troops.\nWorking in the combat zone, the media representative is obliged to comply <a href='/rules'>Rules of work</a>."
                },
                {
                    id: random(1, 99999),
                    title: "How is media work regulated in combat zones?",
                    content: "To maintain operational security, military personnel may, in certain cases, restrict photo and video recording. Operational security units also have the right to preview captured photo and video materials and demand their deletion from data carriers if necessary.\nDuring work in the red zone, media representatives undertake to submit collected photo and video materials for verification regarding the presence of restricted access information, to coordinate their distribution and publication with a military official."
                },
                {
                    id: random(1, 99999),
                    title: "What liability do media representatives bear for disclosing classified information?",
                    content: "Article 114-2 of the Criminal Code of Ukraine provides for imprisonment for a term of 3 to 5 years for unauthorized dissemination of information about the direction, movement of weapons, armaments, and ammunition to Ukraine, movement, or deployment of the Armed Forces of Ukraine or other military formations established in accordance with the laws of Ukraine, committed under conditions of war or a state of emergency."
                }
            ]
        }
    ]
}