import { useState, useEffect } from 'react';

const useAlert = () => {
    const [message, setMessage] = useState();

    useEffect(() => {
        const timer = setTimeout(() => {
            setMessage('');
        }, 15000);

        return () => {
            clearTimeout(timer);
        };
    }, [message]);

    const showAlert = (newMessage) => {
        setMessage(newMessage);
    };

    const hideAlert = () => {
        setMessage('');
    };

    return { message, showAlert, hideAlert };
};

export default useAlert;